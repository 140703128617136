import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function EditMeta() {
    const [infoDetail,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/setting/meta?_id=${urlParams.MetaId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const addMeta = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.page_name, 
            link: values.link, 
            title: values.title, 
            description : values.description , 
            meta_title : values.meta_title , 
            meta_description : values.meta_description , 
            meta_keyword : values.meta_keyword , 
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const addMeta = await Ajax({
            url: `/setting/meta/${urlParams.MetaId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(addMeta.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(addMeta.data.status === "SUCCESS!"){
            toast.success('Meta Updated Successfully')
            setTimeout(function(){
                navigate('/admin/setting/meta')
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Meta">
            <Helmet>
                <title>Edit Meta</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> :
                <Formik
                    initialValues={{ 
                        link: infoDetail.link ? infoDetail.link : '', 
                        title: infoDetail.title ? infoDetail.title : '',
                        page_name: infoDetail.name ? infoDetail.name : '',
                        description: infoDetail.description ? infoDetail.description : '',
                        meta_title: infoDetail.meta_title ? infoDetail.meta_title : '',
                        meta_description: infoDetail.meta_description ? infoDetail.meta_description : '',
                        meta_keyword: infoDetail.meta_keyword ? infoDetail.meta_keyword : '',
                        active: infoDetail.active ? infoDetail.active === true ? 1 : 0 : 0
                    }}
                    validationSchema={Yup.object({
                        link: Yup.string()
                        .required('Field is Required'),
                        title: Yup.string()
                        .required('Field is Required'),
                        meta_title: Yup.string()
                        .required('Field is Required'),
                        meta_description: Yup.string()
                        .required('Field is Required'),
                        active: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addMeta(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <FormGroup label="Select Page" required>
                                <Field as="select" name="link" className='form-select'>
                                    <option value="" disabled>Select</option>
                                    <option value="BUYER">Home (Buy)</option>
                                    <option value="SELLER">Home (Sell)</option>
                                    <option value="about">About Us</option>
                                    <option value="career">Career</option>
                                    <option value="finance">VAS - Home Loan</option>
                                    <option value="home-interiors">VAS - Home Interior</option>
                                    <option value="under-construction-home">VAS - Under Construction Home</option>
                                    <option value="best-area-to-invest">VAS - Best Area</option>
                                    <option value="media_interview">Media Interview</option>
                                    <option value="media_articles">Media Articles</option>
                                    <option value="media_press_release">Media Press Release</option>
                                    <option value="media_awards">Media Awards</option>
                                    <option value="property-listing">Our Properties</option>
                                    <option value="contact-us">Contact Us</option>
                                    <option value="landing-page">Homefest</option>
                                </Field>
                                <ErrorField><ErrorMessage name="link"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Title" required>
                                <Field placeholder="Title" name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Page Name (For Backend)" required>
                                <Field placeholder="Page Name" name="page_name" className='form-control' />
                                <ErrorField><ErrorMessage name="page_name"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Description">
                                <Field as="textarea" placeholder="Description" name="description" className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Title Banner">
                                <input type="file" ref={dataIcon} name="file" className='form-control' />
                                <ErrorField><ErrorMessage name="file"/></ErrorField>
                            </FormGroup>
                        </div>
                        {infoDetail.file &&
                        <div className='col-md-2'>
                            <FormGroup label="Preview">
                                <img src={infoDetail.file.path} className="img-fluid" />
                            </FormGroup>
                        </div> }
                        <div className='col-md-12'>
                            <FormGroup label="Meta Title" required>
                                <Field placeholder="Meta Title" name="meta_title" className='form-control' />
                                <ErrorField><ErrorMessage name="meta_title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Meta Description" required>
                                <Field as="textarea" placeholder="Meta Description" name="meta_description" className='form-control' />
                                <ErrorField><ErrorMessage name="meta_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Meta Keyword">
                                <Field as="textarea" placeholder="Meta Keyword" name="meta_keyword" className='form-control' />
                                <ErrorField><ErrorMessage name="meta_keyword"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Active" required>
                                <Field as="select" name="active" className='form-select'>
                                    <option value="" disabled>Select</option>
                                    <option value={1}>Enable</option>
                                    <option value={0}>Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="active"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>}
            </DataView> 
        </ContentLayout>
    )
}
