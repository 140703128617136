import React from 'react'

export default function PaginationContainer({children, count}) {
  return count >= 10 && (
    <div className="row">
        <div className="col-sm-12 text-start">
            <div className='react-pagination'>
                {children}
            </div>
        </div>
    </div>
  )
}
