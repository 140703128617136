import React, { useState, useEffect, useRef } from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import Modal from 'react-modal';
import DataView from '../../../../UI/Dashboard/DataView'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import RandomId from '../../../../../helpers/RandomId'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import TableContainer from '../../../../UI/Dashboard/TableContainer'
import HomeScreenBanner from './homeScreenBanner'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { CKEditor } from 'ckeditor4-react';

export default function AppConfig() {
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [configObject, setConfigObject] = useState()
  const [homeScreenBanner, setHomeScreenBanner] = useState([])
  const [cityState, setCityState] = useState()
  const [addNewState, setAddNewState] = useState()
  const [addNewCities, setAddNewCities] = useState()
  const [errorMessages, setErrorMessages] = useState()
  const [isOpen, setIsOpen] = useState(false);  
  const [editorContent, setEditorContent] = useState(null)
  const [aboutUsBanner, setAboutUsBanner] = useState("")
  const [mouDefaultDocLink,setMouDefaultDocLink] = useState("")
  const [leadDetailBanner, setLeadDetailBanner] = useState("")
  const userToken = localStorage.getItem("userToken")

  Modal.setAppElement('#root');
  useEffect(() => {
    (async () => {
        const headers = {
            'x-user':userToken,
            "x-api-key":"houseeazy-dev"
        }
      let apiUrl = `${process.env.REACT_APP_JOURNEY_URL}/admin/app-versions`
       if(id){
         apiUrl = `${process.env.REACT_APP_JOURNEY_URL}/admin/app-versions?id=${id}`
       }
      const response = await axios.get(apiUrl,{headers})
      if (response && response.data && response.data.data && response.data.data.apiVersion && response.data.data.apiVersion.length > 0) {
        setConfigObject(response.data.data.apiVersion[0])
        if(response.data.data.apiVersion[0].aboutUsText){
          setEditorContent(response.data.data.apiVersion[0].aboutUsText)
        }
        if(response.data.data.apiVersion[0].aboutUsBanner){
          setAboutUsBanner(response.data.data.apiVersion[0].aboutUsBanner)
        }
        if(response.data.data.apiVersion[0].leadDetailBanner){
          setLeadDetailBanner(response.data.data.apiVersion[0].leadDetailBanner)
        }
        const addedSpecs = []
        const homeScreenBanner = response.data.data.apiVersion[0].homeScreenBanner
        if (homeScreenBanner && homeScreenBanner.length > 0) {
          homeScreenBanner.forEach((e) => {
            addedSpecs.push({
              key: RandomId(20),
              slug: e.slug,
              url: e.url,
              propertyName: e.propertyName ? e.propertyName : "",
              propertyId:e.propertyId ? e.propertyId : "",
              isProperty:e.isProperty ? e.isProperty : false
            })
          })
          setHomeScreenBanner(addedSpecs)
        }
        if (response && response.data && response.data.data && response.data.data.apiVersion  && response.data.data.apiVersion.length > 0 && response.data.data.apiVersion[0].cityState){
          let sortedKeys = Object.keys(response.data.data.apiVersion[0].cityState).sort();
          let sortedObj = {};
                sortedKeys.forEach(key => {
                    sortedObj[key] = response.data.data.apiVersion[0].cityState[key];
                });
          setCityState(sortedObj);
        }
        if(response && response.data && response.data.data && response.data.data.apiVersion && response.data.data.apiVersion.length > 0 && response.data.data.apiVersion[0].errorMessages){
           setErrorMessages(response.data.data.apiVersion[0].errorMessages)
        }
        if(response && response.data && response.data.data && response.data.data.apiVersion && response.data.data.apiVersion.length > 0 && response.data.data.apiVersion[0].aboutUsBanner){
          setAboutUsBanner(response.data.data.apiVersion[0].aboutUsBanner)
        }
        if(response && response.data && response.data.data && response.data.data.apiVersion && response.data.data.apiVersion.length > 0 && response.data.data.apiVersion[0].leadDetailBanner){
         setLeadDetailBanner(response.data.data.apiVersion[0].leadDetailBanner)
        }
        if(response && response.data && response.data.data && response.data.data.apiVersion && response.data.data.apiVersion.length > 0 && response.data.data.apiVersion[0].mouDefaultDocLink){
          setMouDefaultDocLink(response.data.data.apiVersion[0].mouDefaultDocLink)
         }
      }
    })()
  }, [])
  
  const addConfigDetails = async (values, event) => {
    let menu = {}
    if (!Array.isArray(values.bhkList)) {
      values.bhkList = values.bhkList.split(',')
    }
    if (!Array.isArray(values.areaOfOperation)) {
      values.areaOfOperation = values.areaOfOperation.split(',')
    }
    if (!Array.isArray(values.priceRange)) {
      values.priceRange = values.priceRange.split(',')
    }
    if(values.tncmenu){
         menu.tnc = values.tncmenu
    }

    const filterData = []
    if(homeScreenBanner && homeScreenBanner.length > 0){
      
      homeScreenBanner.map((item)=>{
        delete item.key
        if(item.url){
          filterData.push(item)
        }
      })
    }
    const data = {
      mouDefaultDocLink: mouDefaultDocLink,
      aboutUsText: values.aboutUsText, 
      ...(editorContent !== null && {aboutUsText: editorContent}),
      menu: menu,
      areaOfOperation: values.areaOfOperation,
      tnc: values.tnc,
      helplineNumber: values.helplineNumber,
      cityState: cityState,
      bhkList: values.bhkList,
      priceRange: values.priceRange,
      androidAppVersion:values.androidVersion,
      iosAppVersion:values.iosVersion,
      baseUrl:configObject.baseUrl || "",
      esmsURL:configObject.esmsURL || "",
      errorMessages:errorMessages || {},
      showForceUpdate:configObject.showForceUpdate || false,
      aboutUsBanner:aboutUsBanner || "",
      leadDetailBanner:leadDetailBanner || "",
      homeScreenBanner:filterData || []
    }
    
      const headers = {
        'Content-Type': 'application/json',
        'x-user':userToken,
        "x-api-key":"houseeazy-dev"
      }
    if(id){
    let updateConfig = await axios.put(`${process.env.REACT_APP_JOURNEY_URL}/admin/app-version/${id}`, JSON.stringify(data),{headers})
    if (updateConfig.status !== 200) {
      toast.error('Something Went Wrong Please Check')
    } else {
      toast.success('Config update successfully')
      setTimeout(function () {
        window.location.reload()
      }, 500)
    }
  }else{
    let addConfig = await axios.post(`${process.env.REACT_APP_JOURNEY_URL}/admin/app-version`, JSON.stringify(data),{headers})
    if (addConfig.status !== 200) {
      toast.error('Something Went Wrong Please Check')
    } else {
      toast.success('Config add successfully')
      setTimeout(function () {
        navigate('/admin/setting/configList')
      }, 500)
    }
  }
  }
 
  const addNewHomeScreenBanner = () => {
    let num = 0
    const data = {
      key: RandomId(20)
    }
    setHomeScreenBanner([...homeScreenBanner, data])
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  
  const handleDeleteState = (state) => {
    let deleteConfirm = window.confirm("Are you sure want to delete ?")
        if(deleteConfirm === true) {
          const newState = { ...cityState };
          delete newState[state];
          setCityState(newState);
        }
  };

  const handleCityState = (event) => {
    const newState = { ...cityState };
    newState[event.target.name] = event.target.value.split(','); // Assuming cities are comma-separated
    setCityState(newState);
  }

  
  const handleAddCityState = () => {
    let newCities = []
    if(addNewCities) newCities = addNewCities.split(",")
    const newStates = { ...cityState };
    newStates[addNewState] = newCities; // Assuming cities are comma-separated
    let sortedKeys = Object.keys(newStates).sort();
          let sortedObj = {};
                sortedKeys.forEach(key => {
                    sortedObj[key] = newStates[key];
                });
    setCityState(sortedObj);
    // setCityState(newStates);
    setIsOpen(false)
  }

  const uploadImage = async (e) =>{
    document.querySelector(".form-loader").classList.add("show")
    const file = e.target.files[0]
    const formData = new FormData();
    formData.append("file", file);
    const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
    const reqHeaders = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.post(URL, formData, reqHeaders);
    const uploadDocRes = response.data;
    console.log(uploadDocRes,"uploadDocRes")
    if(uploadDocRes && uploadDocRes.length >0){
      if(e.target.name == "aboutUsBanner"){
           setAboutUsBanner(uploadDocRes[0])
      }else if(e.target.name == "leadDetailBanner"){
           setLeadDetailBanner(uploadDocRes[0])
      }else if(e.target.name == "mouDefaultDocLink"){
        setMouDefaultDocLink(uploadDocRes[0])
      }
    }
    document.querySelector(".form-loader").classList.remove("show")
  }

  return (
    <ContentLayout title="App config">
      <Helmet>
        <title>App config</title>
      </Helmet>
      <DataView title="Infomation">
        {configObject == null ? <DataLoader /> :
          <Formik
            initialValues={{
              mouDefaultDocLink: mouDefaultDocLink ? mouDefaultDocLink : "",
              homeScreenBanner: homeScreenBanner || [],
              aboutUsText: configObject.aboutUsText || "",
              aboutUsBanner: aboutUsBanner || '',
              tncmenu: (configObject && configObject.menu && configObject.menu.tnc) ? configObject.menu.tnc : {},
              tnc: configObject.tnc || "",
              helplineNumber: configObject.helplineNumber || "",
              areaOfOperation: configObject.areaOfOperation || [],
              cityState: configObject.cityState || [],
              bhkList: configObject.bhkList || [],
              priceRange: configObject.priceRange || [],
              leadDetailBanner: leadDetailBanner || "",
              androidVersion:configObject.androidAppVersion  || 0,
              iosVersion:configObject.iosAppVersion || 0,
              baseUrl:configObject.baseUrl || "",
              esmsURL:configObject.esmsURL || "",
              errorMessages:configObject.errorMessages || {}
            }}
            onSubmit={(values, event) => {
              addConfigDetails(values, event)
            }}
          >
            <Form>
              <ul className="nav nav-tabs">
                <li className="nav-item"><a href="#tab-info" data-bs-toggle="tab" className="nav-link active">Config Details</a></li>
                <li className="nav-item"><a href="#tab-homeScreenBanner" data-bs-toggle="tab" className="nav-link">Home screen</a></li>
                <li className="nav-item"><a href="#tab-cityDetails" data-bs-toggle="tab" className="nav-link">City State</a></li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id="tab-info">
                  <div className='row'>
                    <div className='col-md-12'>
                      <FormGroup label="Mou Default Doc Link" >
                        {/* <Field placeholder='Mou Default Doc Link' name="mouDefaultDocLink" className='form-control' />
                        <ErrorField><ErrorMessage name="mouDefaultDocLink" /></ErrorField> */}
                        <div className='col-md-12'>
                          <input type="file" onChange={uploadImage} name="mouDefaultDocLink" className='form-control' />
                        </div>
                      </FormGroup>
                      {mouDefaultDocLink && 
                     <a href={mouDefaultDocLink} target='_blank'><i className="fas fa-file-pdf" style={{"font-size":"48px","color":"red"}}></i></a>
                      }
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <FormGroup label="Android Version" >
                        <Field type="number" placeholder='Android Version' name="androidVersion" className='form-control' />
                        <ErrorField><ErrorMessage name="androidVersion" /></ErrorField>
                      </FormGroup>
                      </div>
                      <div className='col-md-6'>
                      <FormGroup label="Ios Version" >
                        <Field type="number" placeholder='Ios Version' name="iosVersion" className='form-control' />
                        <ErrorField><ErrorMessage name="iosVersion" /></ErrorField>
                      </FormGroup>
                      </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <FormGroup label="About Us" >
                        {/* <Field as="textarea" placeholder='About Us' name="aboutUsText" className='form-control' />
                        <ErrorField><ErrorMessage name="aboutUsText" /></ErrorField> */}
                        <CKEditor initData={editorContent} onChange={(e)=>setEditorContent(e.editor.getData())} />
                      </FormGroup>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <FormGroup label="About Us Banner" >
                        <div className='col-md-12'>
                          <input type="file" onChange={uploadImage} name="aboutUsBanner" className='form-control' />
                        </div>
                      </FormGroup>

                      {aboutUsBanner &&
                        // <div className='image-thumbs mb-3'>
                        <img src={aboutUsBanner} width={80} />
                        // </div>
                      }
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                    <FormGroup label="Menu TNC" >
                        <Field type="text" placeholder='Menu Tnc' name="tncmenu" className='form-control' />
                        <ErrorField><ErrorMessage name="tncmenu" /></ErrorField>
                        </FormGroup>
                    </div>
                    <div className='col-md-6'>
                    <FormGroup label="Tnc" >
                        <Field type="Text" placeholder='Tnc' name="tnc" className='form-control' />
                        <ErrorField><ErrorMessage name="tnc" /></ErrorField>
                      </FormGroup>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <FormGroup label="Helpline Number" >
                        <Field type="Text" placeholder='Helpline Number' name="helplineNumber" className='form-control' />
                        <ErrorField><ErrorMessage name="helplineNumber" /></ErrorField>
                      </FormGroup>
                      <FormGroup label="Areas Of Operation" >
                        <Field as="textarea" placeholder='Area Of Operation' name="areaOfOperation" className='form-control' />
                        <ErrorField><ErrorMessage name="areaOfOperation" /></ErrorField>
                      </FormGroup>
                    </div>

                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <FormGroup label="BHK List" >
                        <Field as="textarea" placeholder='BHK list' name="bhkList" className='form-control' />
                        <ErrorField><ErrorMessage name="bhkList" /></ErrorField>
                      </FormGroup>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <FormGroup label="Price Range" >
                        <Field as="textarea" placeholder='Price Range' name="priceRange" className='form-control' />
                        <ErrorField><ErrorMessage name="priceRange" /></ErrorField>
                      </FormGroup>
                      {/* <FormGroup label="lead Detail Banner" >
                        <Field type="text" value={configObject.leadDetailBanner} placeholder='lead Detail Banner' name="leadDetailBanner" className='form-control' />
                        <ErrorField><ErrorMessage name="leadDetailBanner" /></ErrorField>
                      </FormGroup> */}
                      <FormGroup label="Lead Detail Banner" >
                        <div className='col-md-12'>
                          <input type="file" onChange={uploadImage} name="leadDetailBanner" className='form-control' />
                        </div>
                      </FormGroup>

                      {leadDetailBanner &&
                        // <div className='image-thumbs mb-3'>
                        <img src={leadDetailBanner} width={80} />
                        // </div>
                      }
                    </div>

                  </div>
                </div>
                <div className="tab-pane" id="tab-homeScreenBanner">
                  <TableContainer>
                    <thead>
                      <tr>
                        <td className="text-start">Slug</td>
                        <td className="text-start">Image</td>
                        <td className='text-start'>Is Property</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {homeScreenBanner.length > 0 && homeScreenBanner.map((data, index) => {
                        return (
                          <>
                            <HomeScreenBanner key={data.key} data={homeScreenBanner} target={data.key} action={setHomeScreenBanner} label={data.slug} value={data.url} propertyName={data.propertyName ? data.propertyName : ""} propertyId={data.propertyId ? data.propertyId : ""} isProperty={data.isProperty ? data.isProperty : false} id={id?true:false} />
                          </>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3"></td>
                        <td className="text-end">
                          <button type="button" className="btn btn-primary" onClick={addNewHomeScreenBanner}>
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </TableContainer>
                </div>
                <div className="tab-pane" id="tab-cityDetails">
                  <div style={{ textAlign: 'right' }}>
                    <input onClick={openModal} value="Add more" className="btn btn-primary" />
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={closeModal}
                      contentLabel="Example Popup"
                    >
                      <div>
                      <div style={{textAlign:'right'}}>
                        <button onClick={closeModal}><i className="fas fa-times" style={{"font-size":"24px"}}></i></button>
                        </div>
                        <h2>Add more city</h2>
                        <div className='row'>
                          <div className='col-md-12'>
                            <FormGroup label="State" >
                              <Field type="text" placeholder='State' name="state" onChange={(e)=>setAddNewState(e.target.value)} className='form-control' />
                              <ErrorField><ErrorMessage name="state" /></ErrorField>
                            </FormGroup>
                            <FormGroup label="City" >
                              <Field as="textarea" placeholder='City' name="city" onChange={(e)=>setAddNewCities(e.target.value)} className='form-control' />
                              <ErrorField><ErrorMessage name="city" /></ErrorField>
                            </FormGroup>
                          </div>
                        </div>
                        <div>
                          <input value="Add" type='button' className='btn btn-primary' onClick={handleAddCityState}/>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  {cityState &&
                    <div className='row'>
                      {Object.entries(cityState).map(([state, cities]) => (
                        <div key={state}>
                          <FormGroup label={state} >
                            <Field as="textarea" placeholder='City State' value={cities.join(',')} onChange={handleCityState} name={state} className='form-control' />
                            <ErrorField><ErrorMessage name={state} /></ErrorField>
                          </FormGroup>
                          <div style={{ textAlign: 'right' }}>
                          <input class="btn btn-danger" value={`Delete ${state}`} type="button" onClick={() => handleDeleteState(state)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
              <div className='col-md-12 d-grid'>
                <center>
                <Button type="submit">{id ? "Update" : "Add"}</Button>
                </center>
              </div>
            </Form>
          </Formik>
        }
      </DataView>
    </ContentLayout>
  )
}
