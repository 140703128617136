import React, {useEffect, useState} from 'react'
import Ajax from '../../../../helpers/Ajax'
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../Common/ContentLayout';
import EditButton from '../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../UI/Dashboard/Pagination';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment'
import * as XLSX from 'sheetjs-style'
import { toast } from 'react-toastify'

export default function LeadEnquiry() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    useEffect(()=>{
        const getResponse = setTimeout(function(){  
            (async()=>{ 
            const response = await Ajax({
                url: `/enquiry/lead${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
                token: true,
                ...(pageParams && {loader: true})
            })
            if(response.data.status === "SUCCESS!"){
                setDataList(response.data.result)
                setTotalResult(response.data.count)
                setTotalRows(response.data.count / 10)
                setInitialPage(pageParams)
                setCurrentPage(searchParams.get('page'))
            }
            })()
        },100) 
        return()=>{
            clearInterval(getResponse)
        }
    },[location])

    const exportCSV = async () => {
        const records = await Ajax({
          url: '/enquiry/lead?limit=false',
          token: true,
          loader: true
        })
        if(records.data.result.length === 0) {
            toast.error("Record not found!",{
              autoClose:1500,
            })
            return false
        }
        const excelData = []
        const recordData = records.data.result
        for(var e in recordData){
            const creatDate = new Date(recordData[e].createdAt)
            const year = creatDate.getFullYear();
            const month = ("0" + (creatDate.getMonth() + 1)).slice(-2);
            const day = ("0" + creatDate.getDate()).slice(-2);
        
            const isoDate = `${day}-${month}-${year}`
            excelData.push(
                {
                    "Phone": recordData[e].phone ? recordData[e].phone : '',
                    "name": recordData[e].name ? recordData[e].name : '',
                    "date": isoDate ? isoDate : ''
                }
            )
        }
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Lead-Enquiry.xlsx");
            
    }

    return (
    <ContentLayout title='Lead Enquiry' exportExcel={['Export Excel', exportCSV]}>
        <div className="row">
        <Helmet>
            <title>Lead Enquiry</title>
        </Helmet>
        <DataView title="Lead Enquiry List">
            <TableContainer>
                <thead>
                    <tr>
                        <td width={80} className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">Phone</td>
                        <td className="text-start">Date / Time</td>
                        <td className="text-center">Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={5}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={5} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.name}</td>
                        <td className="text-start">{data.phone}</td>
                        <td className="text-start">{moment(data.createdAt).format('DD MMM YYYY')} / {moment(data.createdAt).format('HH:mm')}</td>
                        <td className="text-center">
                            <DeleteButton url="/enquiry/lead" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/enquiry/lead' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
