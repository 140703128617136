import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function WhyChooseDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const whyIcon = useRef(null)
    const whyBg = useRef(null)
    const [iconError, setIconError] = useState()
    const [bgError, setBgError] = useState()
    const urlParams = useParams()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/home/whyChoose?_id=${urlParams.WhyChooseId}`,
                token: true
              })
              console.log(response)
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
        const formData = new FormData()
        const data = {
            name: values.why_title, 
            description: values.why_description, 
            ...(whyIcon.current.value != '' && {file: whyIcon.current.files[0]}),
            ...(whyBg.current.value != '' && {blog: whyBg.current.files[0]}),
            sortOrder: values.sort_order, 
            type: values.type, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
            if(data[key] === "") {
                toast.error('Some Fields are Empty please check',{
                    toastId: 'EmptyFields'
                })
                return false
            }
        }
        const update = await Ajax({
            url: `/home/whyChoose/${urlParams.WhyChooseId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
        console.log(update)
    }
    return (
        <ContentLayout title="Why Choose Detail">
            <Helmet>
                <title>Why Choose Us Detail</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> :
                <>
                <div className='lulu'>{infoDetail.type && infoDetail.type}</div>
                <Formik
                    initialValues={{ 
                        why_title: infoDetail.name ? infoDetail.name : '', 
                        why_description: infoDetail.description ? infoDetail.description : '', 
                        sort_order: infoDetail.sortOrder ? infoDetail.sortOrder : '',
                        type: infoDetail.type ? infoDetail.type : ''
                    }}
                    validationSchema={Yup.object({
                        why_title: Yup.string()
                        .required('Field is Required'),
                        why_description: Yup.string()
                        .required('Field is Required'),
                        sort_order: Yup.string()
                        .required('Field is Required'),
                        type: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <FormGroup label="Title" required>
                        <Field placeholder='Title' name="why_title" className='form-control' />
                        <ErrorField><ErrorMessage name="why_title"/></ErrorField>
                    </FormGroup>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Icon" required>
                                <input type="file" ref={whyIcon} name="why_icon" className='form-control' />
                                <ErrorField>{iconError}</ErrorField>
                            </FormGroup>
                            {infoDetail.file && <div className='bg-dark d-inline-block mt-1'>
                                <img src={infoDetail.file.path} width="80px" alt="icon" />
                            </div> }
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Background" required>
                                <input type="file" ref={whyBg} name="why_bg" className='form-control' />
                                <ErrorField>{bgError}</ErrorField>
                            </FormGroup>
                            {infoDetail.blog && <div className='bg-dark d-inline-block mt-1'>
                                <img src={infoDetail.blog.path} width="80px" alt="icon" />
                            </div> }
                        </div>
                    </div>
                    <FormGroup label="Description" required>
                        <Field as="textarea" placeholder='Description' name="why_description" className='form-control' />
                        <ErrorField><ErrorMessage name="why_description"/></ErrorField>
                    </FormGroup>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type" className='form-select'>
                                    <option value="" disabled>Select</option>
                                    <option value="SELLER">Seller</option>
                                    <option value="BUYER">Buyer</option>
                                    <option value="PRODUCT">Product</option>
                                    <option value="HOME-LOAN">Home Loan</option>
                                    <option value="HOME-INTERIOR">Home Interior</option>
                                    <option value="UNDER-CONTRUCTION">Under Construction</option>
                                    <option value="BEST-AREA">Best Area</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field type="number" placeholder='Sort Order' name="sort_order" className='form-control' />
                                <ErrorField><ErrorMessage name="sort_order"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>
                </>
                }
            </DataView>
        </ContentLayout>
    )
}
