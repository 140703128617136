import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AmenityDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/career/list?_id=${urlParams.JobId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            position: values.position, 
            location: values.location, 
            description: values.description, 
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/career/${urlParams.JobId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Job Details Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Job Detail">
            <Helmet>
                <title>Edit Job Detail</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        position: infoDetail.position ? infoDetail.position : '', 
                        location: infoDetail.location ? infoDetail.location : '', 
                        description: infoDetail.description ? infoDetail.description : '', 
                        active: infoDetail.active === true ? 1 : 2
                    }}
                    validationSchema={Yup.object({
                        position: Yup.string().required('Field is Required'),
                        location: Yup.string().required('Field is Required'),
                        description: Yup.string().required('Field is Required'),
                        active: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                    <div className='col-md-6'>
                            <FormGroup label="Position" required>
                                <Field placeholder='Position' name="position" className='form-control' />
                                <ErrorField><ErrorMessage name="position"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Location" required>
                                <Field placeholder='Location' name="location" className='form-control' />
                                <ErrorField><ErrorMessage name="location"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                        {infoDetail.file? 
                        <a href={infoDetail.file.path} target="_blank">
                        <i class="fa fa-file-pdf fa-4x" aria-hidden="true"></i>
                        </a> 
                        :''}
                            <FormGroup label="Pdf" required>
                                <input type="file" accept='.pdf, .PDF' name="amenity_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                       
                        <div className='col-md-6'>
                        <FormGroup label="Active" required>
                        <Field as="select" name="active" className='form-select'>
                            <option value="1">Enable</option>
                            <option value="2">Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    </div>
                    <div className='col-md-12'>
                            <FormGroup label="Description" required>
                                <Field as="textarea" name="description"  className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
