import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../../helpers/GetState'
import GetCity from '../../../../../helpers/GetCity'
import GetLocation from '../../../../../helpers/GetLocation'

export default function EditSector() {
    const [infoDetail, setInfoDetail] = useState(null)
    const [states, setStates] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const urlParams = useParams()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const stateList = await GetState()
                setStates(stateList)

                const locationData = await Ajax({
                    url: `/setting/getSector?_id=${urlParams.SectorId}`,
                    token: true
                })
                if(locationData.data.status === 'SUCCESS!'){
                    setInfoDetail(locationData.data.result[0])
                    setSelectedState(locationData.data.result[0].state._id)
                    setSelectedCity(locationData.data.result[0].city._id)
                    const cityFetch = await GetCity(locationData.data.result[0].state._id)
                    setCity(cityFetch)
                    const locationFetch = await GetLocation(locationData.data.result[0].city._id)
                    setLocation(locationFetch)
                }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    //Seelct State for City
    const chooseState = async(e) => {
        var stateId = e.target.value
        setSelectedState(stateId)
        const cityFetch = await GetCity(stateId)
        setCity(cityFetch)
    }

    //Select City for Location
    const chooseCity = async(e) => {
        var cityId = e.target.value
        setSelectedCity(cityId)
        const locationFetch = await GetLocation(cityId)
        setLocation(locationFetch)
    }

    const updateData = async (values, event) => {
        if(selectedState === ''){
            toast.error('Please select state!',{
                toastId: 'stateError',
                autoClose: 1000
            })
        }
        const data = {
            name: values.title, 
            state: selectedState, 
            city : selectedCity,
            location : values.location,
            active: values.active == 1 ? true : false
        }
        const update = await Ajax({
            url: `/setting/sector/${urlParams.SectorId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Sector Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Sector">
            <Helmet>
                <title>Edit Sector</title>
            </Helmet>
            <DataView title="Infomation">
                {infoDetail === null ? <DataLoader /> :
                <Formik
                    initialValues={{ 
                        location: infoDetail.location ? infoDetail.location._id : '',
                        title: infoDetail.name ? infoDetail.name : '',
                        active: infoDetail.active === true ? 1 : 0
                    }}
                    validationSchema={Yup.object({
                        location: Yup.string()
                        .required('Field is Required'),
                        title: Yup.string()
                        .required('Field is Required')
                    
                    })}
                    onSubmit={(values, event) => {
                        updateData(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <FormGroup label="State" required>
                                <select name="state" className='form-select' value={selectedState} onChange={chooseState}>
                                    <option value="" disabled>Select State</option>
                                    {states === null ? <option value='' disabled>Searching...</option> :  states.map(e=>(
                                        <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="City" required>
                                <select as="select" name="city" className='form-select' value={selectedCity} onChange={chooseCity}>
                                    <option value="" disabled>Select City</option>
                                    {city === null? <option value='' disabled>Select State</option> :  city.map(e=>(
                                            <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Location" required>
                                <Field as="select" name="location" className='form-select'>
                                    <option value="" disabled>Select Location</option>
                                    {location === null? <option value='' disabled>Select City</option> :  location.map(e=>(
                                            <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </Field>
                                <ErrorField><ErrorMessage name="location"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <FormGroup label="Active" required>
                        <Field as="select" type="number" name="active" className='form-select'>
                            <option value={1}>Enable</option>
                            <option value={0}>Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="active"/></ErrorField>
                    </FormGroup>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
