import React, {useEffect, useState, useContext} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import Button from '../../../../UI/Form/Button'
import FormGroup from '../../../../UI/Form/FormGroup'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Pagination from '../../../../UI/Dashboard/Pagination';
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import ViewButton from '../../../../UI/Dashboard/ViewButton';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import FilterOptions from '../../../../../helpers/FilterOptions';
import FilterLayout from '../../../../UI/Dashboard/FilterLayout';
import ContentLayout from '../../../../Common/ContentLayout';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';
import EditButton from '../../../../UI/Dashboard/EditButton';

export default function PropertyList() {
    const [listInfo, setListInfo] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/setting/meta${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}${Object.keys(filterKey).length === 0 ? '' : `&${new URLSearchParams(filterKey)}`}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setListInfo(response.data.result)
            setTotalResult(response.data.count)
            setTotalRows(response.data.count / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
        
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    //Filters
    const [filterKey, setFilterKey] = useState({})
    const setFilter = (e) => {
        const fieldName = e.target.getAttribute("name")
        let updatedValue = {};
        if(e.target.value.trim().length === 0) {
            delete filterKey[fieldName]
            return false
        }
        updatedValue[fieldName] = e.target.value.trim()
        setFilterKey(oldValue => ({...oldValue, ...updatedValue}));
    }

    const filterHandler = async() => {
        const filterResponse = await Ajax({
            url: '/setting/meta?' + new URLSearchParams(filterKey),
            token: true,
            loader: true
        })
        if(filterResponse.data.result.length === 0){
            setListInfo(filterResponse.data.result)
            toast.error('Matching Record not found!',{
                autoClose: 1500
            })
            setSearch(true)
        }else{
            setCurrentPage(0)
            setInitialPage(0)
            setListInfo(filterResponse.data.result)
            setTotalRows(filterResponse.data.countData / 10)
            setTotalResult(filterResponse.data.countData)
            pageParams && navigate('/admin/home/service')
            return false
        }
    }

    return (
    <ContentLayout title='Meta List' addNew='/admin/setting/meta/add-new'>
        <div className="row">
        <Helmet>
            <title>Meta List</title>
        </Helmet>
        <DataView title="Meta List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Page</td>
                        <td className="text-start">Meta Title</td>
                        <td className="text-center">Status</td>
                        <td className="text-center">Edit</td> 
                        <td className="text-center">Delete</td> 
                    </tr>
                </thead>
                <tbody>
                    {listInfo == null ? 
                    <TableLoader cols={6}/> : listInfo.length === 0 ? 
                    <tr>
                        <td colSpan={6} className="text-center">Data not available</td>
                    </tr> : listInfo.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.name}</td>
                        <td className="text-start">{data.meta_title}</td>
                        <td className="text-center">
                            {data.active === true ? 
                            <span className="badge bg-success">Active</span> : 
                            <span className="badge bg-danger">Disable</span>
                            }
                        </td> 
                        <td className="text-center">
                            <EditButton>{`/admin/setting/meta/${data._id}`}</EditButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/setting/meta" id={data._id}/>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/setting/meta' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
