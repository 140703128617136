import React from 'react'

export default function FilterLayout({children}) {
  return (
    <div className="col-lg-12 mb-3">
        <div className="card">
            <div className="card-header"><i className="fas fa-filter"></i> Filter</div>
            <div className="card-body">
                {children}
            </div>
        </div>
    </div>
  )
}
