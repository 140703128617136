import React, {useEffect, useState} from 'react'
import Ajax from '../../../../helpers/Ajax'
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../Common/ContentLayout'
import EditButton from '../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../UI/Dashboard/Pagination';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import * as XLSX from 'sheetjs-style'
import { toast } from 'react-toastify'
import moment from 'moment/moment';

export default function AmenityList() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/buyer/schedule${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
            setTotalResult(response.data.countData)
            setTotalRows(response.data.countData / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
       
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    const exportCSV = async () => {
        const records = await Ajax({
          url: '/buyer/schedule?limit=false',
          token: true,
          loader: true
        })
        if(records.data.result.length === 0) {
            toast.error("Record not found!",{
              autoClose:1500,
            })
            return false
        }
        const excelData = []
        const recordData = records.data.result
        for(var e in recordData){
            excelData.push(
                {
                    "Date": recordData[e].createdAt ? moment(recordData[e].createdAt).format('YYYY-MM-DD / HH:mm') : '',
                    "Name": recordData[e].name ? recordData[e].name : '',
                    "Email": recordData[e].email ? recordData[e].email : '',
                    "Phone": recordData[e].phone ? recordData[e].phone : '',
                    "Schedule Date": recordData[e].date ? recordData[e].date : '',
                    "Schedule Time": recordData[e].time ? recordData[e].time : '',
                    "Location": recordData[e].location ? recordData[e].location : '',
                    "Property Name": recordData[e].productId && recordData[e].productId.name ? recordData[e].productId.name : '',
                    "BHK": recordData[e].productId && recordData[e].productId.bhk ? recordData[e].productId.bhk.name : '',
                    "Unit Number": recordData[e].productId && recordData[e].productId.unitNumber ? recordData[e].productId.unitNumber : '',
                }
            )
        }
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Seller-Schedule.xlsx");
            
    }

    return (
    <ContentLayout title='Buyer Schedule' exportExcel={['Export Excel', exportCSV]}>
        <div className="row">
        <Helmet>
            <title>Buyer Schedule</title>
        </Helmet>
        <DataView title="Buyer Schedule">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Property</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">Email Address</td>
                        <td className="text-start">Contact Number</td>
                        <td className="text-start">Location</td>
                        <td className="text-center">Date & Time</td>
                       
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={7}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={7} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start ">{data.productId && data.productId.name ? data.productId.name :''}</td>
                        <td className="text-start ">{data.name?data.name:''}</td>
                        <td className="text-start">{data.email?data.email:''}</td>
                        <td className="text-start ">{data.phone?data.phone:''}</td>
                        <td className="text-start ">{data.location?data.location:''}</td>
                         <td className="text-start ">{data.date?data.date:''} {data.time?data.time:''}</td>
                        
                       
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/user/buyer/schedule' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
