import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function ValueDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/home/services?_id=${urlParams.ValueAddedId}`,
                token: true
              })
              console.log(response.data.result[0].sortOrder)
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
        const data = {
            name: values.value_title,  
            link: values.value_url,
            sortOrder: values.sort_order,
            type: values.type === '1' ? 'SELLER' : 'BUYER',
        }
        const update = await Ajax({
            url: `/home/services/${urlParams.ValueAddedId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Value Added Detail">
            <Helmet>
                <title>Value Added Detail</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        value_title: infoDetail.name ? infoDetail.name : '', 
                        sort_order: infoDetail.sortOrder ? infoDetail.sortOrder : '',
                        value_url: infoDetail.link ? infoDetail.link : '',
                        type: infoDetail.type === 'SELLER' ? '1' : '2'
                    }}
                    validationSchema={Yup.object({
                        value_title: Yup.string()
                        .required('Field is Required'),
                        value_url: Yup.string()
                        .required('Field is Required'),
                        type: Yup.string()
                        .required('Field is Required'),
                        sort_order: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <FormGroup label="Title" required>
                        <Field placeholder='Title' name="value_title" className='form-control' />
                        <ErrorField><ErrorMessage name="value_title"/></ErrorField>
                    </FormGroup>  
                    <FormGroup label="Url" required>
                        <Field placeholder='Url' name="value_url" className='form-control' />
                        <ErrorField><ErrorMessage name="value_url"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Sort Order" required>
                        <Field type="number" placeholder='Sort Order' name="sort_order" className='form-control' />
                        <ErrorField><ErrorMessage name="sort_order"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Type" required>
                        <Field as="select" name="type" className='form-select'>
                            <option value="" disabled>Select</option>
                            <option value="1">Seller</option>
                            <option value="2">Buyer</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
