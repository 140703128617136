import React from 'react'
import { Link } from 'react-router-dom'

export default function ContentLayout({title, children, addNew, exportExcel,notification}) {
  return (
    <div id="content">
        <div className="page-header">
            <div className="container-fluid">
              <div className="float-end">
                {addNew && 
                <Link to={addNew} className='btn btn-primary'><i className="fas fa-plus"></i></Link> }
                {exportExcel && Array.isArray(exportExcel) &&
                <button type="button" onClick={exportExcel[1]} className='btn btn-primary ms-2'>{exportExcel[0]}</button> }
                {notification && 
               <div>{notification}</div>
               }
              </div>
                <h1>{title}</h1>
            </div>
        </div>
        <div className="container-fluid">
            {children}
        </div>
    </div>
  )
}
