import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewWhyChoose() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
      
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])




    const addData = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.heading,
            description: values.description,
            sortOrder: values.sortOrder,
            type: values.type
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/home/whychoose`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Added Successfully')
            setTimeout(function(){
                navigate('/admin/vas/why-choose-us')
            },500)
        }
    }
    return (
        <ContentLayout title="Add New">
            <Helmet>
                <title>Add New</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        description:'',
                        heading:'',
                        sortOrder: '1',
                        type: ''
                    }}
                    validationSchema={Yup.object({
                        description: Yup.string().required('Field is Required'),
                        heading: Yup.string().required('Field is Required'),
                        type: Yup.string().required('Field is Required'),
                        sortOrder: Yup.string().required('Field is Required'),
                       
                    })}
                    onSubmit={(values, event) => {
                        addData(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                   
                        <div className='col-md-6'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="heading" className='form-control' />
                                <ErrorField><ErrorMessage name="heading"/></ErrorField>
                            </FormGroup>
                        </div>
                      
                      
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type"  className='form-select' >
                                <option value='' disabled>Select Type</option>
                                <option value='SELLER'>Seller</option>
                                <option value='BUYER'>Buyer</option>
                                <option value='HOME-LOAN'>VAS - Home Loan</option>
                                <option value='HOME-INTERIOR'>VAS - Home Interior</option>
                                <option value='UNDER-CONTRUCTION'>VAS - Under Construction</option>
                                <option value='BEST-AREA'>VAS - Best Area</option>
                             
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Description" required>
                                <Field as="textarea" name="description"  className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Image">
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field type="number" placeholder='Sort Order' name="sortOrder" className='form-control' />
                                <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
