import React, {useState} from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import TitleToSlug from '../../../helpers/TitleToSlug';
export default function NavItem({children, url, icon, dropdown, active}) {
  const location = useLocation();
  const dropDownId = TitleToSlug(children)
  let isActive = false
  for(let i = 0; i < active.length; i++){
    if(active[i] === location.pathname){
      isActive = true
    }
  }
  return !dropdown ? (
    <li className={isActive === true ? 'active' : ''}>
        <NavLink to={url}><i className={icon}></i> {children}</NavLink>
    </li>
  ) : (
    <li className={isActive === true ? 'active' : ''}>
        <a href={`#${dropDownId}-menu`} data-bs-toggle="collapse" className={`parent ${isActive === true ? '' : 'collapsed'}`}><i className={icon}></i> {children}</a>
        <ul id={dropDownId+'-menu'} className={`collapse ${isActive === true ? 'show' : ''}`}>
            {dropdown.map((data)=>(
              <li key={data.title}>
                {!data.dropdown ?
                <NavLink to={data.url}>{data.title}</NavLink>
                 : <a href={'#'+TitleToSlug(data.title)+'-menu'} data-bs-toggle="collapse" className="parent collapsed">{data.title}</a> }
                 {data.dropdown && 
                 <ul id={TitleToSlug(data.title)+'-menu'} className={`collapse ${isActive === true ? 'show' : ''}`}>
                      {data.dropdown.map((menu)=>(
                        <li key={menu.title}><NavLink to={menu.url}>{menu.title}</NavLink></li>
                      ))}
                  </ul> }
              </li>
            ))}
        </ul>
    </li>
  )
}
