import React from "react"
export default function TableLoader({cols = 5, rows = 5, title}) {
    let fields = []
    let columns = []
    for(let i = 0; i < rows; i++){
        fields = [...fields, {"key": i}]
    }
    for(let i = 0; i < cols; i++){
        columns = [...columns, {"key": i}]
    }
    return (
    <>
    {fields.map((data, index)=>(
        <tr key={index+'table-field'}>
            {columns.map((data, index)=>(
                <td key={index+'table-col-field'}><div className='field-loader'></div></td>
            ))}
        </tr>
        ))}
    </>
    )
}
