import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../helpers/GetState'
import GetCity from '../../../../helpers/GetCity'
import GetLocation from '../../../../helpers/GetLocation'
import GetSector from '../../../../helpers/GetSector'
import TableContainer from '../../../UI/Dashboard/TableContainer'
import Specification from '../../../UI/Dashboard/AddNewProperty/Specification'
import GetAmenity from '../../../../helpers/GetAmenity'
import GetDeveloper from '../../../../helpers/GetDeveloper'
import RandomId from '../../../../helpers/RandomId'
import Select from 'react-select'
import GetBhk from '../../../../helpers/GetBhk'
import GetFurnishing from '../../../../helpers/GetFurnishing'
import PropertyList from '../../../../helpers/PropertyList'

export default function AddNewProperty() {
    const urlParams = useParams()
    const navigate = useNavigate()
    const [states, setStates] = useState(null)
    const [bhk, setBhk] = useState(null)
    const [furnishing, setFurnishing] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [sector, setSector] = useState(null)
    const [amenities, setAmenities] = useState(null)
    const [developers, setDevelopers] = useState(null)
    const [selectedAmenity, setSelectedAmenity] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')
    const [selectedSector, setSelectedSector] = useState('')
    const [selectedDeveloper, setSelectedDeveloper] = useState('')
    const [propertyList, setPropertyList] = useState([])
    const [selectedProperty, setSelectedProperty] = useState([])
    const [developerDetails,setDeveloperDetail] = useState('')
    const [drone,setDrone] = useState('')
    const [nearByTitle1,setNearByTitle1] = useState('')
    const [nearByTitle2,setNearByTitle2] = useState('')
    const [nearByTitle3,setNearByTitle3] = useState('')
    const [nearBySubTitle1,setNearBySubTitle1] = useState('')
    const [nearBySubTitle2,setNearBySubTitle2] = useState('')
    const [nearBySubTitle3,setNearBySubTitle3] = useState('')
    const [nearByTitleIcon1,setNearByTitleIcon1] = useState('')
    const [nearByTitleIcon2,setNearByTitleIcon2] = useState('')
    const [nearByTitleIcon3,setNearByTitleIcon3] = useState('')
    const [landmark,setLandmark] = useState('')
    const [googleMap,setGoogleMap] = useState('')
    const [projectVideo,setProjectVideo] = useState('')
    const [emiCalculate, setEmiCalculate] = useState(0)
    const [price, setPrice] = useState()
    const [disableTrue, setDisableTrue] = useState(false)

    useEffect(()=>{
        (async()=>{
            (async()=>{
                const stateList = await GetState()
                setStates(stateList)
                })();
               
                (async()=>{
                    const developerList = await GetDeveloper()
                    setDevelopers(developerList)
                    })();
                
                (async()=>{
                const bhkList = await GetBhk()
                setBhk(bhkList)
                })();
        
                (async()=>{
                const furnishingList = await GetFurnishing()
                setFurnishing(furnishingList)
                })();
        
                (async()=>{
                const amenity = await GetAmenity()
                const amenityArray = []
                for(let i in amenity){
                    amenityArray.push({
                        label: `${amenity[i].name}`,
                        value: amenity[i]._id,
                    })
                }
                setAmenities(amenityArray)
                })();
        
                (async()=>{
                    const property = await PropertyList()
                    const propertyArray = []
                    for(let i in property){
                        propertyArray.push({
                            label: `${property[i].name}, ${property[i].unitNumber ? property[i].unitNumber : ''}, ${property[i].area ? property[i].area : ''}, ${property[i].bhk ? property[i].bhk.name : ''}, ${property[i].price ? property[i].price : ""}`,
                            value: property[i]._id,
                        })
                    }
                    setPropertyList(propertyArray)
                })();
        })()
    },[])

    //Add Product
    const productImage = useRef()
    const projectImage = useRef()
    const matterportImage = useRef()
    const thumbnailImage = useRef()
    const nearByIcon1 = useRef()
    const nearByIcon2 = useRef()
    const nearByIcon3 = useRef()
    const addProduct = async (values, event) => {
        let specName = document.querySelectorAll("[name='specification_title']")
        let specValue = document.querySelectorAll("[name='specification_value']")
        let addedSpecs = []
        let addedAmenity = []
        let addedProperty = []
        for(let i = 0; i < selectedAmenity.length; i++){
            addedAmenity.push(selectedAmenity[i].value)
        }
        for(let i = 0; i < selectedProperty.length; i++){
            addedProperty.push(selectedProperty[i].value)
        }
        let projectVideos = []
        if(projectVideo){
            let projectVideoSplit = projectVideo.split(',')
            for(let i = 0; i < projectVideoSplit.length; i++){
                projectVideos.push(projectVideoSplit[i])
            }
        }
        projectVideos = JSON.stringify(projectVideos)
        const data = {
            name: values.prop_name,
            ...(values.slug && {slug: values.slug}),
            shortDescription: values.shortDescription,
            // description: values.description,
            developer:selectedDeveloper,
            price: price,
            tour3D: values.tour3D,
            map: googleMap,
            //specifications: addedSpecs,
            // location: values.location,
            // floor: values.floor,
            facing: values.facing,
            area: values.area,
            landmark: landmark || "",
            bhk: values.bhk,
            state: selectedState,
            city: selectedCity,
            locationId: selectedLocation,
            sector: selectedSector,
            drone: drone || "",
            //amenities: addedAmenity,
            hotdeal: values.hotdeal,
            soldout: Number(values.soldout),
            //dateOfToken: values.dateOfToken,
            emi: emiCalculate,
            unitNumber: values.unitNumber,
            bathrooms: values.bathrooms,
            balconies: values.balconies,
            furnishing: values.furnishing,
            unitFloor: values.unitFloor,
            // totalFloor: values.totalFloor,
            parking: values.parking,
            metaTitle: values.metaTitle,
            metaKeyword: values.metaKeyword,
            metaDescription: values.metaDescription,
            showOnLp: values.showOnLp === 1 ? true : false,
            nearbyTitle1: nearByTitle1 || "",
            nearbySubTitle1: nearBySubTitle1 || "",
            nearbyTitle2: nearByTitle2 || "",
            nearbySubTitle2: nearBySubTitle2 || "",
            nearbyTitle3:  nearByTitle3 || "",
            nearbySubTitle3: nearBySubTitle3 || "",
            active: values.active == 1 ? true : false,
            youtube:projectVideos,

            parkingType: values.parkingType
        }
        const formData = new FormData()

        if(productImage.current.value === ''){
            toast.error('Apartment Photos are required',{
                toastId: 'iconRequired'
            })
            return false
        }
        if(productImage.current.value !== ''){
            for(let i = 0; i < productImage.current.files.length; i++){
                formData.append("file", productImage.current.files[i])
            }
        }
        if(projectImage.current.value !== ''){
            for(let i = 0; i < projectImage.current.files.length; i++){
                formData.append("coverPhoto", projectImage.current.files[i])
            }
        }else if(developerDetails.projectPhotos){
             formData.append("coverPhoto",JSON.stringify(developerDetails.projectPhotos))
        }else{
            toast.error('Project Photos are required',{
                toastId: 'iconRequired'
            })
            return false
        }
        if(nearByIcon1.current.value !== ''){
            for(let i = 0; i < nearByIcon1.current.files.length; i++){
                formData.append("nearbyIcon1", nearByIcon1.current.files[i])
            }
        }else if(developerDetails.nearbyIcon1 && developerDetails.nearbyIcon1._id){
            formData.append("nearbyIcon1",developerDetails.nearbyIcon1._id)
        }
        if(nearByIcon2.current.value !== ''){
            for(let i = 0; i < nearByIcon2.current.files.length; i++){
                formData.append("nearbyIcon2", nearByIcon2.current.files[i])
            }
        }else if(developerDetails.nearbyIcon2 && developerDetails.nearbyIcon2._id){
            formData.append("nearbyIcon2",developerDetails.nearbyIcon2._id)
        }
        if(nearByIcon3.current.value !== ''){
            for(let i = 0; i < nearByIcon3.current.files.length; i++){
                formData.append("nearbyIcon3", nearByIcon3.current.files[i])
            }
        }else if(developerDetails.nearbyIcon3 && developerDetails.nearbyIcon3._id){
            formData.append("nearbyIcon3",developerDetails.nearbyIcon3._id)
        }
        if(specName.length < 7 || specValue.length < 7){
            toast.error('Specifications all fields are required',{
                toastId: 'EmptyFields'
            })
            return false
        }else {
            for(let i = 0; i < specName.length; i++){
                // addedSpecs.push({
                //     "name": specName[i].value,
                //     "value": specValue[i].value,
                // })
                if(specValue[i].value != ""){
                    formData.append("specifications["+i+"][name]", specName[i].value)
                    formData.append("specifications["+i+"][value]", specValue[i].value)
                }else{
                    toast.error('Specifications all fields are required',{
                        toastId: 'EmptyFields'
                    })
                    return false
                }
            }
        }
        if(matterportImage.current.value !== ''){ 
            formData.append("blog", matterportImage.current.files[0])
        }
        if(thumbnailImage.current.value === ''){
            toast.error('Thumbnail Image is required',{
                toastId: 'iconRequired'
            })
            return false
        }
        if(thumbnailImage.current.value !== ''){
            formData.append("thumbnail", thumbnailImage.current.files[0])
        }
        if(selectedAmenity.length === 0){
            toast.error('Amenities are required',{
                toastId: 'iconRequired'
            })
            return false
        }else{
            for(let i = 0; i < selectedAmenity.length; i++){
                formData.append("amenities["+i+"]", selectedAmenity[i].value)
            }
        }
        if(selectedProperty.length == 0){
            toast.error('Related property is required',{
                toastId: 'iconRequired'
            })
            return false
        }else{
            for(let i = 0; i < selectedProperty.length; i++){
                formData.append("relatedProperty["+i+"]", selectedProperty[i].value)
            }
        }
        for(var key in data) {
            if(data[key] === "" && key != 'tour3D' &&  key != 'threeDimage' &&  key != 'emi' && key != "drone" && key != "youtube" &&   key != 'nearbyTitle1' &&  key != 'nearbySubTitle1' &&  key != 'nearbyTitle2' &&  key != 'nearbySubTitle2' &&  key != 'nearbyTitle3' &&  key != 'nearbySubTitle3' && key != "landmark" && key != "parkingType" && key != "sector") {
                toast.error(`Some Fields are Empty please check (${key})`,{
                    toastId: 'EmptyFields'
                })
                return false
            }

            if(key == 'sector'){
                if(data[key] !== null) formData.append(key, data[key]);
            }else{
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/product`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Property Added Successfully')
            setTimeout(function(){
                navigate('/admin/property-management/properties')
            },500)
        }
    }

    //Seelct State for City
    const chooseState = async(e) => {
        var stateId = e.target.value
        setSelectedState(stateId)
        const cityFetch = await GetCity(stateId)
        setCity(cityFetch)
    }

    //Select City for Location
    const chooseCity = async(e) => {
        var cityId = e.target.value
        setSelectedCity(cityId)
        const locationFetch = await GetLocation(cityId)
        setLocation(locationFetch)
    }

    //Select Location for sector
    const chooseLocation = async(e) => {
        var locationId = e.target.value
        setSelectedLocation(locationId)
        const sectorFetch = await GetSector(locationId)
        setSector(sectorFetch)
    }

    const handleEmiCalculator = async(e)=>{
        setPrice(e.target.value)
        // let EMI = PMT(9%/12,20*12,(price*1.09*0.85))
        let monthlyInterestRate = 9 / 100 / 12;

            // Convert loan term from years to months
            let totalPayments = 20 * 12;

            // Calculate monthly payment
            let monthlyPayment = (e.target.value*1.09*0.85) * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments)));

            // Round to two decimal places
            // monthlyPayment = Math.round(monthlyPayment);
        setEmiCalculate(monthlyPayment.toFixed(2))
    }

    //Add Specification
    const [specifications, setSpecifications] = useState([])
    const addNewSpecification = () => {
        let num = 0
        const data = {
            key: RandomId(20)
        }
        setSpecifications([...specifications, data])
    }

    //Select Amenity
    const handleAmenity = (selectedAmenity) => {
        setSelectedAmenity([...selectedAmenity])
    }

     //Select Property
     const handleProperty = (selectedProperty) => {
        setSelectedProperty([...selectedProperty])
    }
    const deleteProductImage = async(id) => {
        const deleteResponse = await Ajax({
            url: `/file/${id}`,
            method: 'DELETE',
            loader:true,
            token: true
        })
        if(deleteResponse.data.error){
            toast.error(deleteResponse.data.error)
        }
        if(deleteResponse.data.status === 'SUCCESS!'){
            toast.success('Image deleted successfully!')
            document.querySelector("[data-image-thumb='"+id+"']").remove()
        }
    }
    const handleDeveloper = async (e) =>{
        const response = await Ajax({
            url: `/developer?limit=false&_id=${e.target.value}`,
            token: true,
            loader:true
        })
        if(response.data.status == "SUCCESS!"){
            setSelectedDeveloper(response.data.result.docs[0]._id)
            setDeveloperDetail(response.data.result.docs[0])
            setSelectedState(response.data.result.docs[0].state._id)
            const cityFetch = await GetCity(response.data.result.docs[0].state._id)
            setCity(cityFetch)
            setSelectedCity(response.data.result.docs[0].city._id)
            const locationFetch = await GetLocation(response.data.result.docs[0].city._id)
            setLocation(locationFetch)
            setSelectedLocation(response.data.result.docs[0].location._id)
            const sectorFetch = await GetSector(response.data.result.docs[0].location._id)
            setSector(sectorFetch)
            setSelectedSector(response.data.result.docs[0]?.sector?._id ?? null)
            const amenityData = response.data.result.docs[0].amenities || []
                    if(amenityData !== null){
                        const addedAmenityArray = []
                        amenityData.forEach((e)=>{
                            addedAmenityArray.push({
                                label: e.name,
                                value: e._id,
                            })
                    })
            setSelectedAmenity(addedAmenityArray)
            }
            setDrone(response.data.result.docs[0].drone || "")
            setNearByTitle1(response.data.result.docs[0].nearbyTitle1 || "")
            setNearByTitle2(response.data.result.docs[0].nearbyTitle2 || "")
            setNearByTitle3(response.data.result.docs[0].nearbyTitle3 || "")
            setNearBySubTitle1(response.data.result.docs[0].nearbySubTitle1 || "")
            setNearBySubTitle2(response.data.result.docs[0].nearbySubTitle2 || "")
            setNearBySubTitle3(response.data.result.docs[0].nearbySubTitle3 || "")
            setNearByTitleIcon1(response.data.result.docs[0].nearbyIcon1 && response.data.result.docs[0].nearbyIcon1.path ? response.data.result.docs[0].nearbyIcon1.path : "")
            setNearByTitleIcon2(response.data.result.docs[0].nearbyIcon2 && response.data.result.docs[0].nearbyIcon2.path ? response.data.result.docs[0].nearbyIcon2.path : "")
            setNearByTitleIcon3( response.data.result.docs[0].nearbyIcon3 && response.data.result.docs[0].nearbyIcon3.path ? response.data.result.docs[0].nearbyIcon3.path : "")
            setProjectVideo(response.data.result.docs[0].youtube || "")
            setLandmark(response.data.result.docs[0].landmark ? response.data.result.docs[0].landmark : "")
            setGoogleMap(response.data.result.docs[0].map || "")
            // document.getElementById('drone').disabled = true
            setDisableTrue(true)
        }else{
            toast.error("Developer doesn't exist")       
        }
    }
    //Select Near By
    // const handleNearBy = (selectedNearBy) => {
    //     setSelectedNearBy({selectedNearBy})
    // }

    const handleImageSize =(e)=>{
        const files = e.target.files || []
        if(files && files.length > 0){
            for(const file of files){
            if(file.size > 1024*1024) {
                toast.error('Image size should not exceed 1 MB',{
                    toastId: 'iconRequired'
                })
                e.target.value = ''
                return false
            }
        }
        }
    }

    return (
        <ContentLayout title="Add New Property">
            <Helmet>
                <title>Add New Property</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        prop_name: '',
                        slug: '',
                        shortDescription: '',
                        description: '',
                        developer: '',
                        price: '',
                        files: '',
                        projectPhotos: '',
                        drone: '',
                        tour3D: '',
                        threeDimage: '',
                        map: '',
                        specifications: '',
                        location: '',
                        floor: '',
                        facing: '',
                        area: '',
                        landmark: '',
                        bhk: '',
                        city: '',
                        locationId: '',
                        sector: '',
                        amenities: '',
                        hotdeal: 1,
                        soldout: 1,
                        //dateOfToken: '',
                        emi: '',
                        unitNumber: '',
                        bathrooms: '',
                        balconies: '',
                        furnishing: '',
                        unitFloor: '',
                        // totalFloor: '',
                        parking: '',
                        developer: '',
                        metaTitle: '',
                        metaKeyword: '',
                        metaDescription: '',
                        showOnLp: 0,
                        nearbyTitle1:'',
                        nearbySubTitle1: '',
                        nearbyTitle2: '',
                        nearbySubTitle2: '',
                        nearbyTitle3:'',
                        nearbySubTitle3:'',
                        active: 1,
                        parkingType: ''
                    }}
                    // validationSchema={Yup.object({
                    //     process_title: Yup.string()
                    //     .required('Field is Required'),
                    //     process_description: Yup.string()
                    //     .required('Field is Required'),
                    //     sort_order: Yup.string()
                    //     .required('Field is Required'),
                    //     type: Yup.string()
                    //     .required('Field is Required')
                    // })}
                    onSubmit={(values, event) => {
                        addProduct(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-info" data-bs-toggle="tab" className="nav-link active">Product Info</a></li>
                        <li className="nav-item"><a href="#tab-meta-info" data-bs-toggle="tab" className="nav-link">Meta Info</a></li>
                        <li className="nav-item"><a href="#tab-image-map" data-bs-toggle="tab" className="nav-link">Image / Map</a></li>
                        <li className="nav-item"><a href="#tab-features" data-bs-toggle="tab" className="nav-link">Features</a></li>
                        <li className="nav-item"><a href="#tab-location" data-bs-toggle="tab" className="nav-link">Location</a></li>
                        <li className="nav-item"><a href="#tab-near-buy" data-bs-toggle="tab" className="nav-link">Near By</a></li>
                        <li className="nav-item"><a href="#tab-related-property" data-bs-toggle="tab" className="nav-link">Related Property</a></li>
                        <li className="nav-item"><a href="#tab-specification" data-bs-toggle="tab" className="nav-link">Specification</a></li>
                        <li className="nav-item"><a href="#tab-amenity-nearby" data-bs-toggle="tab" className="nav-link">Amenity</a></li>
                    </ul>
                    <div className="tab-content">

                        <div className="tab-pane active" id="tab-info">
                            <div className='row'>
                            <div className='col-md-6'>
                                    <FormGroup label="Developer" required>
                                        <Field as="select" type="number" name="developer" value={selectedDeveloper} onChange={handleDeveloper} className='form-select'>
                                            <option value="" disabled>Select</option>
                                            {developers === null ? <option disabled>Loading</option> : developers.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="developer"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                <FormGroup label="Property Name" required>
                                <Field placeholder='Property Name' name="prop_name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                               </FormGroup>
                                </div>
                            </div>
                            
                            <FormGroup label="Short Description" required>
                                <Field as="textarea" placeholder='Sort Description' name="shortDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="shortDescription"/></ErrorField>
                            </FormGroup>
                            {/* <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="description" className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup> */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Price"  required>
                                        <Field type="number" name="price" value={price} onChange={handleEmiCalculator} className='form-control' />
                                        <ErrorField><ErrorMessage name="price"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="EMI">
                                        <Field name="emi" value={emiCalculate} className='form-control'/>
                                        <ErrorField><ErrorMessage name="emi"/></ErrorField>
                                    </FormGroup>
                                </div>
                                
                                <div className='col-md-4'>
                                    <FormGroup label="Soldout" required>
                                        <Field as="select" name="soldout" className='form-select'>
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="soldout"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Hot Deals" required>
                                        <Field as="select" name="hotdeal" className='form-select'>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="hotdeal"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Show on Homefest Page">
                                        <Field as="select" name="showOnLp" className='form-select'>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="showOnLp"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup label="Active" required>
                                <Field as="select" type="number" name="active" className='form-select'>
                                    <option value={1}>Enable</option>
                                    <option value={0}>Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="active"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-meta-info">
                            <FormGroup label="Meta Title" required>
                                <Field placeholder='Meta Title' name="metaTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="metaTitle"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Meta Description" required>
                                <Field as="textarea" placeholder='Meta Description' name="metaDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="metaDescription"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Meta Keyword" required>
                                <Field as="textarea" placeholder='Meta Keyword' name="metaKeyword" className='form-control' />
                                <ErrorField><ErrorMessage name="metaKeyword"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Product Url (Optional)" >
                                <Field placeholder='Product Url' name="slug" className='form-control' />
                                <ErrorField><ErrorMessage name="slug"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-image-map">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Thumbnail Image" required>
                                        <input type="file" name="thumbnail" ref={thumbnailImage} onChange={handleImageSize} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="files"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Apartment Photos" required>
                                        <input type="file" name="files" ref={productImage} onChange={handleImageSize}  className='form-control' multiple accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="files"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Project Photos" >
                                        <input type="file" name="projectPhotos" onChange={handleImageSize} disabled={disableTrue == true ? "disabled" : ""}  ref={projectImage} className='form-control' multiple accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="projectPhotos"/></ErrorField>
                                    </FormGroup>
                                    {developerDetails && developerDetails.projectPhotos && developerDetails.projectPhotos.length > 0 && 
                                    <div className='image-thumbs mb-3'>
                                        {developerDetails.projectPhotos.map((e)=>(
                                            <div key={e._id} className='single-thumb' data-image-thumb={e._id}>
                                                {/* <button type="button" onClick={()=>deleteProductImage(e._id)} className="btn btn-danger">
                                                    <i className="fas fa-trash"></i>
                                                </button> */}
                                                <img src={e.path} width={80} />
                                            </div>
                                        ))}
                                    </div> }
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Drone Video (Youtube Id)" >
                                        <Field placeholder="id" disabled={disableTrue == true ? "disabled" : ""} name="drone" value={drone} onChange={(e)=>setDrone(e.target.value)} className='form-control' />
                                        <ErrorField><ErrorMessage name="drone"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup label="Project Videos (Youtube Id)" >
                                <Field as="textarea" placeholder="id1,id2" name="youtube" value={projectVideo} disabled={disableTrue == true ? "disabled" : ""}  onChange={(e)=>setProjectVideo(e.target.value)} className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Google Map (Iframe)" required>
                                <Field as="textarea" placeholder="Google Map (Iframe)" disabled={disableTrue == true ? "disabled" : ""}  value={googleMap} onChange={(e)=>setGoogleMap(e.target.value)} name="map" className='form-control' />
                                <ErrorField><ErrorMessage name="map"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Matterport Url">
                                        <Field placeholder="Matterport Url" name="tour3D" className='form-control' />
                                        <ErrorField><ErrorMessage name="tour3D"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Matterport Image">
                                        <input type="file" name="threeDimage" ref={matterportImage} onChange={handleImageSize}  className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="threeDimage"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div> 
                        </div>
                        <div className="tab-pane" id="tab-features">
                            <div className='row'>
                                {/* <div className='col-md-4'>
                                    <FormGroup label="Location" required>
                                        <Field name="location" placeholder="Location" className='form-control'/>
                                        <ErrorField><ErrorMessage name="location"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Floor" required>
                                        <Field name="floor" placeholder="Floor" className='form-control'/>
                                        <ErrorField><ErrorMessage name="floor"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                <div className='col-md-4'>
                                    <FormGroup label="Facing" required>
                                        <Field name="facing" placeholder="Facing" className='form-control'/>
                                        <ErrorField><ErrorMessage name="facing"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Area" required>
                                        <Field name="area" placeholder="Area" className='form-control'/>
                                        <ErrorField><ErrorMessage name="area"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Landmark" >
                                        <Field name="landmark" placeholder="Landmark" disabled={disableTrue == true ? "disabled" : ""}  value={landmark} onChange={(e)=>setLandmark(e.target.value)} className='form-control'/>
                                        <ErrorField><ErrorMessage name="landmark"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="BHK" required>
                                        <Field as="select" name="bhk" className='form-select'>
                                            <option value="" disabled>Select</option>
                                            {bhk === null ? <option disabled>Loading</option> : bhk.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="bhk"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Unit Number" required>
                                        <Field name="unitNumber" placeholder="Unit Number" className='form-control'/>
                                        <ErrorField><ErrorMessage name="unitNumber"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Bathroom" required>
                                        <Field name="bathrooms" placeholder="Bathroom" className='form-control'/>
                                        <ErrorField><ErrorMessage name="bathrooms"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Balcony" required>
                                        <Field name="balconies" placeholder="Balcony" className='form-control'/>
                                        <ErrorField><ErrorMessage name="balconies"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Furnishing" required>
                                        <Field as="select" name="furnishing" className='form-select'>
                                            <option value="" disabled>Select</option>
                                            {furnishing === null ? <option disabled>Loading</option> : furnishing.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="furnishing"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Unit Floor" required>
                                        <Field name="unitFloor" placeholder="Unit Floor" className='form-control'/>
                                        <ErrorField><ErrorMessage name="unitFloor"/></ErrorField>
                                    </FormGroup>
                                </div>
                                {/* <div className='col-md-4'>
                                    <FormGroup label="Total Floor" required>
                                        <Field name="totalFloor" placeholder="Total Floor" className='form-control'/>
                                        <ErrorField><ErrorMessage name="totalFloor"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                <div className='col-md-4'>
                                    <FormGroup label="Parking" required>
                                        <Field name="parking" placeholder="Parking" className='form-control'/>
                                        <ErrorField><ErrorMessage name="parking"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Parking type">
                                        <Field as="select" name="parkingType" className='form-select'>
                                            <option value="">Select</option>
                                            <option value="Open" >Open</option>
                                            <option value="Closed" >Closed</option>
                                            <option value="Open & Closed" >Open & Closed</option>
                                        </Field>
                                        <ErrorField><ErrorMessage name="parkingType"/></ErrorField>
                                    </FormGroup>
                                </div>
                                {/* <div className='col-md-4'>
                                    <FormGroup label="Date of Token" required>
                                        <Field type="date" name="dateOfToken" className='form-control'/>
                                        <ErrorField><ErrorMessage name="dateOfToken"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                {/* <div className='col-md-4'>
                                    <FormGroup label="EMI">
                                        <Field name="emi" className='form-control'/>
                                        <ErrorField><ErrorMessage name="emi"/></ErrorField>
                                    </FormGroup>
                                </div> */}
                                
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-location">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="State" required>
                                        <select name="state" disabled={disableTrue == true ? "disabled" : ""}  className='form-select' value={selectedState} onChange={chooseState}>
                                            <option value="" disabled>Select</option>
                                            {states === null ? <option disabled>Loading</option> : states.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="state"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="City"  required>
                                        <select name="city" value={selectedCity} disabled={disableTrue == true ? "disabled" : ""} className='form-select' onChange={chooseCity}>
                                            <option value="" disabled>Select</option>
                                            {city === null ? <option disabled>Select State</option> : city.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="city"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Location"  required>
                                        <select disabled={disableTrue == true ? "disabled" : ""} name="locationId" value={selectedLocation} className='form-select' onChange={chooseLocation}>
                                            <option value="" disabled>Select</option>
                                            {location == null ? <option disabled>Loading</option> : location.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="locationId"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Sector" >
                                        <select name="sector" disabled={disableTrue == true ? "disabled" : ""} value={selectedSector} onChange={(e)=>setSelectedSector(e.target.value)} className='form-select'>
                                            <option value="">None</option>
                                            {sector == null ? <option disabled>Loading</option> : sector.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="sector"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-near-buy">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title">
                                        <Field name="nearbyTitle1" value={nearByTitle1} disabled={disableTrue == true ? "disabled" : ""}  onChange={(e)=>setNearByTitle1(e.target.value)} className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title">
                                        <Field name="nearbySubTitle1" disabled={disableTrue == true ? "disabled" : ""}  value={nearBySubTitle1} onChange={(e)=>setNearBySubTitle1(e.target.value)} className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon">
                                        <input type="file" name="nearbyIcon1" onChange={handleImageSize}  disabled={disableTrue == true ? "disabled" : ""}  ref={nearByIcon1}  className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon1"/></ErrorField>
                                    </FormGroup>
                                    {nearByTitleIcon1 && 
                                    <img src={nearByTitleIcon1} width={80} /> }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title">
                                        <Field name="nearbyTitle2" value={nearByTitle2} disabled={disableTrue == true ? "disabled" : ""}  onChange={(e)=>setNearByTitle2(e.target.value)} className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title">
                                        <Field name="nearbySubTitle2" disabled={disableTrue == true ? "disabled" : ""}  value={nearBySubTitle2} onChange={(e)=>setNearBySubTitle2(e.target.value)} className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon">
                                        <input type="file" name="nearbyIcon2" onChange={handleImageSize}  ref={nearByIcon2}  disabled={disableTrue == true ? "disabled" : ""}  className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon2"/></ErrorField>
                                    </FormGroup>
                                    {nearByTitleIcon2 && 
                                    <img src={nearByTitleIcon2} width={80} /> }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title">
                                        <Field name="nearbyTitle3" disabled={disableTrue == true ? "disabled" : ""}  value={nearByTitle3} onChange={(e)=>setNearByTitle3(e.target.value)} className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title">
                                        <Field name="nearbySubTitle3" value={nearBySubTitle3} disabled={disableTrue == true ? "disabled" : ""}  onChange={(e)=>setNearBySubTitle3(e.target.value)}className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon">
                                        <input type="file" onChange={handleImageSize}  disabled={disableTrue == true ? "disabled" : ""}  name="nearbyIcon3" ref={nearByIcon3} className='form-control' accept='.jpg, .jpeg, .png, .webp'/>
                                        <ErrorField><ErrorMessage name="nearbyIcon3"/></ErrorField>
                                    </FormGroup>
                                    {nearByTitleIcon3 && 
                                    <img src={nearByTitleIcon3} width={80} /> }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-related-property">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Select Property" required>
                                        <div className='col-md-12'>
                                            {propertyList !== null ?
                                            <Select 
                                                options={propertyList} 
                                                onChange={handleProperty}
                                                closeMenuOnSelect={false}
                                                isMulti
                                             /> : 'Loading...' }
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-specification">
                            <TableContainer>
                                <thead>
                                    <tr>
                                        <td className="text-start">Title</td>
                                        <td className="text-start">Description</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title" value="Bed" placeholder="Title" className="form-control"/>
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title" value="Bath" placeholder="Title" className="form-control" />
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title" value="Floor" placeholder="Title" className="form-control" />
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title" value="Furnishing" placeholder="Title" className="form-control" />
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title"  value="Balcony" placeholder="Title" className="form-control" />
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title"  value="View & Facing" placeholder="Title" className="form-control" />
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            <input type="text" name="specification_title"  value="Car Parking" placeholder="Title" className="form-control" />
                                        </td>
                                        <td className="text-start">
                                            <div className="input-group">
                                                <input type="text" name="specification_value" placeholder="Value" className="form-control" />
                                            </div>
                                        </td>
                                    </tr>
                                    {specifications.length > 0 && specifications.map((data, index)=>(
                                        <Specification key={data.key} data={specifications} target={data.key} action={setSpecifications} />
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-primary" onClick={addNewSpecification}>
                                                <i className="fas fa-plus-circle"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </TableContainer>
                        </div>
                        <div className="tab-pane" id="tab-amenity-nearby">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Select Amenity" required>
                                        <div className='col-md-12'>
                                            <Select
                                                isDisabled={disableTrue == true ? "disabled" : ""}
                                                value={selectedAmenity}
                                                options={amenities}
                                                onChange={handleAmenity}
                                                closeMenuOnSelect={false}
                                                isMulti
                                             />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add Product</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}