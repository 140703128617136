import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import TableContainer from '../../../UI/Dashboard/TableContainer'
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import RandomId from '../../../../helpers/RandomId'
import GetCookie from '../../../../helpers/GetCookie'

export default function EditSellerProperty() {
    const urlParams = useParams()
    const [productDetail, setProductDetail] = useState(null)
    const [bigImage, setBigImage] = useState()
    const [documents ,setDocuments] = useState([])
    const [paymentPlan ,setPaymentPlan] = useState([])
    const [transactions ,setTransactions] = useState([])
    const [docFile, setDocFile] = useState()
    const [docBase64, setDocBase64] = useState()
    const [docBase64Final, setDocBase64Final] = useState()
    const navigate = useNavigate()
    useEffect(()=>{
        (async()=>{
            const productInfo = await Ajax({
                url: `/seller/property?_id=${urlParams.PropertyId}`,
                token: true
            })
            const productDocs = await Ajax({
                url: `/seller/document?propertyId=${urlParams.PropertyId}`,
                token: true
            })
            const productPlan = await Ajax({
                url: `/seller/paymentPlan?propertyId=${urlParams.PropertyId}`,
                token: true
            })
            const transactionData = await Ajax({
                url: `/seller/transaction?propertyId=${urlParams.PropertyId}`,
                token: true
            })
            if(productInfo.data.status === 'SUCCESS!'){
                setProductDetail(productInfo.data.result[0])
            }
            if(productDocs.data.status === 'SUCCESS!'){
                setDocuments(productDocs.data.result)
            }
            if(productPlan.data.status === 'SUCCESS!'){
                setPaymentPlan(productPlan.data.result)
            }
            if(transactionData.data.status === 'SUCCESS!'){
                setTransactions(transactionData.data.result)
            }
        })()
    },[])

    //Get Base 64
    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            setDocBase64Final(baseURL)
            resolve(baseURL);
          };
          //console.log(fileInfo);
        });
    };
    const getBase64Doc = e => {
        //console.log(e.target.files[0]);
    
        const file = e.target.files[0]
    
        getBase64(file)
          .then(result => {
            file["base64"] = result;
            //console.log("File Is", file);
            setDocBase64(result, file)
          })
          .catch(err => {
            //console.log(err);
          });
          setDocFile(e.target.files[0])
    };

    //Add Product
    const propDocument = useRef()
    const addDocument = async (values, reset) => {
        //console.log(productDetail)
        if(!productDetail.user || !productDetail.user.name || !productDetail.user.email){
            toast.error('Please update name and email')
            navigate(`/admin/user/${productDetail.user._id}`)
            return false
        }
        if(propDocument.current.value === ''){
            toast.error('Please upload document')
            return false
        }
        const data = {
            propertyId: urlParams.PropertyId,
            name: values.document_name,
            user: productDetail.user._id,
        }
        const formData = new FormData()
        if(propDocument.current.value !== ''){
            for(let i = 0; i < propDocument.current.files.length; i++){
                formData.append("file", propDocument.current.files[i])
            }
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/seller/document`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            const docData = {
                "email": productDetail.user && productDetail.user.email ? productDetail.user.email : 'info@houseeazy.com',
                "name": productDetail.user && productDetail.user.name ? productDetail.user.name : 'HouseEazy',
                "recipientId": `${RandomId(8, 'number')}`,
                "subject": `${values.document_name}`,
                "documentId": `${RandomId(8, 'number')}`,
                "documentName": values.document_name,
                "token": GetCookie('houseeazy'),
                "documentBase64": docBase64Final.split(',')[1],
                "userDocumentId": update.data.result._id,
            }
            const documentSend = await Ajax({
                url: '/product/sendAgreement',
                method: 'POST',
                data: docData,
                token: true,
                loader:true
            })
            if(documentSend.data.error){
                toast.error(documentSend.data.error)
                return false
            }
            if(documentSend.data.status === 'SUCCESS!'){
                reset()
                propDocument.current.value = ''
                toast.success('Document Added Successfully')
                setTimeout(function(){
                    window.location.reload()
                },500)
            }
        }
    }

    const propPaymentPlan = useRef()
    const addPaymentPlan = async() => {
        if(!productDetail.user || !productDetail.user.name || !productDetail.user.email){
            toast.error('Please update name and email')
            navigate(`/admin/user/${productDetail.user._id}`)
            return false
        }
        if(propPaymentPlan.current.value === ''){
            toast.error('Please upload document')
            return false
        }
        const data = {
            propertyId: urlParams.PropertyId,
            user: productDetail.user._id,
        }
        const formData = new FormData()
        if(propPaymentPlan.current.value !== ''){
            for(let i = 0; i < propPaymentPlan.current.files.length; i++){
                formData.append("file", propPaymentPlan.current.files[i])
            }
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/seller/paymentPlan`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            propPaymentPlan.current.value = ''
            toast.success('Payment Plan Added Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }

    //Add Transaction
    const addTrasaction = async(values, reset) => {
        const transactionData = {
            "propertyId": urlParams.PropertyId,
            "user": productDetail.user._id,
            "amount":values.amount,
            "receviedDate":values.receviedDate,
            "receviedTime":values.receviedTime,
            "remainingAmount":values.remainingAmount
        }
        const uploadTransaction = await Ajax({
            url: '/seller/transaction',
            token: true,
            method: 'POST',
            data:transactionData,
            loader:true
        })
        if(uploadTransaction.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(uploadTransaction.data.status === "SUCCESS!"){
            propPaymentPlan.current.value = ''
            toast.success('Transaction Added Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }

    return (
        <ContentLayout title="Edit Property">
            <Helmet>
                <title>Edit Property</title>
            </Helmet>
            <DataView title="Infomation">
                {productDetail === null ? <DataLoader/> : 
                    <>
                        <ul className="nav nav-tabs">
                            <li className="nav-item"><a href="#tab-info" data-bs-toggle="tab" className="nav-link active">Property Info</a></li>
                            <li className="nav-item"><a href="#tab-transactions" data-bs-toggle="tab" className="nav-link">Transactions</a></li>
                            <li className="nav-item"><a href="#tab-payment-plan" data-bs-toggle="tab" className="nav-link">Payment Plan</a></li>
                            <li className="nav-item"><a href="#tab-meta-info" data-bs-toggle="tab" className="nav-link">Upload Document</a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="tab-info">
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <FormGroup label="Seller Name">
                                            <div className="form-control">
                                                {productDetail.user && productDetail.user.name ? productDetail.user.name : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-4'>
                                        <FormGroup label="Seller Email">
                                            <div className="form-control">
                                                {productDetail.user && productDetail.user.email ? productDetail.user.email : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-4'>
                                        <FormGroup label="Seller Phone">
                                            <div className="form-control">
                                                {productDetail.user && productDetail.user.phoneNumber ? productDetail.user.phoneNumber : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12'>
                                        <FormGroup label="Society Name">
                                            <div className="form-control">
                                                {productDetail.societyName && productDetail.societyName.name ? productDetail.societyName.name : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="House and Tower Number">
                                            <div className="form-control">
                                                {productDetail.unitNumber ? productDetail.unitNumber : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="Saleable Area of Apartment">
                                            <div className="form-control">
                                                {productDetail.area ? productDetail.area.area : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="Furnishing">
                                            <div className="form-control">
                                                {productDetail.furnishing ? productDetail.furnishing.name : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="Posting Done by">
                                            <div className="form-control">
                                                {productDetail.postingBy ? productDetail.postingBy : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="State">
                                            <div className="form-control">
                                                {productDetail.state ? productDetail.state.name : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="City">
                                            <div className="form-control">
                                                {productDetail.city ? productDetail.city.name : 'NA'}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12'>
                                        <FormGroup label="Images">
                                            {productDetail.file.length === 0 ? 'Images not uploaded' : 
                                            <div className='image-thumbs'>
                                                {productDetail.file.map((e)=>(
                                                    <div key={e._id} className="single-image-thumb" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>setBigImage(e.path)}>
                                                        <img src={e.smallFile} />
                                                    </div>
                                                ))}
                                            </div>
                                            }
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tab-transactions">
                            <Formik
                                    initialValues={{ 
                                        "amount": '',
                                        "receviedDate": '',
                                        "receviedTime": '',
                                        "remainingAmount": ''
                                    }}
                                    validationSchema={Yup.object({
                                        amount: Yup.string()
                                        .required('Field is Required'),
                                        receviedDate: Yup.string()
                                        .required('Field is Required'),
                                        receviedTime: Yup.string()
                                        .required('Field is Required'),
                                        remainingAmount: Yup.string()
                                        .required('Field is Required')
                                    })}
                                    onSubmit={(values, {resetForm}) => {
                                        addTrasaction(values, resetForm)
                                    }}
                                >
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <FormGroup label="Amount" required>
                                                    <Field placeholder="Amount" name="amount" className='form-control' />
                                                    <ErrorField><ErrorMessage name="amount"/></ErrorField>
                                                </FormGroup>
                                            </div>
                                            <div className='col-md-3'>
                                                <FormGroup label="Received Date" required>
                                                    <Field type="date" placeholder="Received Date" name="receviedDate" className='form-control' />
                                                    <ErrorField><ErrorMessage name="receviedDate"/></ErrorField>
                                                </FormGroup>
                                            </div>
                                            <div className='col-md-3'>
                                                <FormGroup label="Received Time" required>
                                                    <Field type="time" placeholder="Received Time" name="receviedTime" className='form-control' />
                                                    <ErrorField><ErrorMessage name="receviedTime"/></ErrorField>
                                                </FormGroup>
                                            </div>
                                            <div className='col-md-3'>
                                                <FormGroup label="Remaining Amount" required>
                                                    <Field placeholder="Remaining Amount" name="remainingAmount" className='form-control' />
                                                    <ErrorField><ErrorMessage name="remainingAmount"/></ErrorField>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className='col-md-2 d-grid'>
                                            <Button type="submit">Add Transactions</Button>
                                        </div>
                                    </Form>
                                </Formik>
                                <div className='mt-3'>
                                    <TableContainer>
                                        <thead>
                                            <tr>
                                                <th width="100">S.No</th>
                                                <th>Amount</th>
                                                <th>Recevied Date</th>
                                                <th>Recevied Time</th>
                                                <th>Remaining Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions.length > 0 ? transactions.map((e, index)=>(
                                                <tr key={e._id}>
                                                    <td width="100">{index + 1}</td>
                                                    <td>{e.amount}</td>
                                                    <td>{e.receviedDate}</td>
                                                    <td>{e.receviedTime}</td>
                                                    <td>{e.remainingAmount}</td>
                                                </tr>
                                            )) : 
                                                <tr>
                                                    <td colSpan={4}>Transactions not uploaded yet</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </TableContainer>
                                </div>
                            </div>
                            <div className="tab-pane" id="tab-payment-plan">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <FormGroup label="Upload Payment Plan" required>
                                            <input type="file" accept=".docx,.doc,.pdf" name="payment_plan" className='form-control' role='button' ref={propPaymentPlan} />
                                        </FormGroup>
                                    </div>
                                    {paymentPlan.length > 0 &&
                                    <div className='col-md-6'>
                                        <FormGroup label="Uploaded Plan" required>
                                            <a href={paymentPlan[0].file.path} download className='btn btn-primary'>Download</a>
                                        </FormGroup>
                                    </div> }
                                </div>
                                <div className='col-md-2 d-grid'>
                                    <Button type="button" onclick={addPaymentPlan}>Upload Payment Plan</Button>
                                </div>
                            </div>
                            <div className="tab-pane" id="tab-meta-info">
                                <Formik
                                    initialValues={{ 
                                        "document_name": ''
                                    }}
                                    validationSchema={Yup.object({
                                        document_name: Yup.string()
                                        .required('Field is Required')
                                    })}
                                    onSubmit={(values, {resetForm}) => {
                                        addDocument(values, resetForm)
                                    }}
                                >
                                <Form>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <FormGroup label="Document Name" required>
                                            <Field placeholder="Document Name" name="document_name" className='form-control' />
                                            <ErrorField><ErrorMessage name="document_name"/></ErrorField>
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <FormGroup label="Upload Document" required>
                                            <input type="file" accept=".docx,.doc,.pdf" name="document" className='form-control' role='button' onChange={getBase64Doc} ref={propDocument} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='col-md-2 d-grid'>
                                    <Button type="submit">Upload Document</Button>
                                </div>
                                </Form>
                                </Formik> 
                                <div className='mt-3'>
                                    <TableContainer>
                                        <thead>
                                            <tr>
                                                <th width="100">S.No</th>
                                                <th>Title</th>
                                                <th>View</th>
                                                <th width="100" className='text-center'>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documents.length > 0 ? documents.map((e, index)=>(
                                                <tr key={e._id}>
                                                    <td width="100">{index + 1}</td>
                                                    <td>{e.name}</td>
                                                    <td>
                                                        <a href={e.file.path} target="_blank" rel="noreferrer" className='btn btn-primary'>
                                                            View
                                                        </a>
                                                    </td>
                                                    <td width="100" className='text-center'>
                                                        <DeleteButton url="/seller/document" id={e._id} />
                                                    </td>
                                                </tr>
                                            )) : 
                                                <tr>
                                                    <td colSpan={4}>Document not uploaded yet</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </DataView>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{productDetail !== null && productDetail.societyName ? productDetail.societyName.name : 'NA'}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <img src={bigImage} className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}
