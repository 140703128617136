import React, {useEffect, useState} from 'react'
import Ajax from '../../../../helpers/Ajax'
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../Common/ContentLayout';
import EditButton from '../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../UI/Dashboard/Pagination';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ViewButton from '../../../UI/Dashboard/ViewButton';
import PopupViewButton from '../../../UI/Dashboard/PopupViewButton';
import * as XLSX from 'sheetjs-style'
import { toast } from 'react-toastify'

export default function ContactUsEnquiry() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    useEffect(()=>{
        const getResponse = setTimeout(function(){  
            (async()=>{ 
            const response = await Ajax({
                url: `/buyer/tour${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
                token: true,
                ...(pageParams && {loader: true})
            })
            if(response.data.status === "SUCCESS!"){
                setDataList(response.data.result)
                // console.log(response.data)
                setTotalResult(response.data.countData)
                setTotalRows(response.data.countData / 10)
                setInitialPage(pageParams)
                setCurrentPage(searchParams.get('page'))
            }
            })()
        },100) 
        return()=>{
            clearInterval(getResponse)
        }
    },[location])

    const [enquiryData, setEnquiryData] = useState(null)
    const viewDetail = (data) => {
        setEnquiryData({
            name: data.user.name,
            email: data.user.email,
            phone: data.user.phoneNumber,
            property: data.productId.name,
            size: data.productId.bhk.name,
            unit: data.productId.unitNumber
        })
    }

    const exportCSV = async () => {
        const records = await Ajax({
          url: '/buyer/tour?limit=false',
          token: true,
          loader: true
        })
        if(records.data.result.length === 0) {
            toast.error("Record not found!",{
              autoClose:1500,
            })
            return false
        }
        const excelData = []
        const recordData = records.data.result
        for(var e in recordData){
            excelData.push(
                {
                    "Date": recordData[e].createdAt ? moment(recordData[e].createdAt).format('YYYY-MM-DD / HH:mm') : '',
                    "Name": recordData[e].user && recordData[e].user.name ? recordData[e].user.name : '',
                    "Phone": recordData[e].user && recordData[e].user.phoneNumber ? recordData[e].user.phoneNumber : '',
                    "Property": `${recordData[e].productId.bhk && recordData[e].productId.bhk.name} - ${recordData[e].productId.name} - ${recordData[e].productId.unitNumber}`,
                    "Email": recordData[e].user && recordData[e].user.email ? recordData[e].user.email : '',
                    "Scheduled Date / Time": `${recordData[e].date && moment.unix(recordData[e].date).format('DD MMM YYYY') != "Invalid date"  ? moment.unix(recordData[e].date).format('DD MMM YYYY') : moment.unix(recordData[e].date).format('DD MMM YYYY') != "null" ? "NA" :recordData[e].date} / ${recordData[e].time && moment(recordData[e].time, 'HH:mm').format('HH:mm')}`,
                }
            )
        }
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Take-Tour-Enquiry.xlsx");
            
    }

    return (
    <ContentLayout title='Take Tour Enquiry' exportExcel={['Export Excel', exportCSV]}>
        <div className="row">
        <Helmet>
            <title>Take Tour Enquiry</title>
        </Helmet>
        <DataView title="Take Tour Enquiry List">
            <TableContainer>
                <thead>
                    <tr>
                        <td width={80} className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">Phone</td>
                        <td className="text-start">Property</td>
                        <td className="text-start">Tour Date / Time</td>
                        <td className="text-start">Date / Time</td>
                        <td className="text-center">View</td>
                        <td className="text-center">Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={8}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={8} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.user && data.user.name ? data.user.name : data.user && data.user.email ? data.user.email : "NA" }</td>
                        <td className="text-start">{data.user && data.user.phoneNumber  ? data.user.phoneNumber : "NA"}</td>
                        <td className="text-start">{`${data.productId.bhk && data.productId.bhk.name} - ${data.productId.name} - ${data.productId.unitNumber}`}</td>
                        <td className="text-start">{`${data.date && moment.unix(data.date).format('DD MMM YYYY') != "Invalid date"  ? moment.unix(data.date).format('DD MMM YYYY') : moment.unix(data.date).format('DD MMM YYYY') != "null" ? "NA" :data.date} / ${data.time && moment(data.time, 'HH:mm').format('HH:mm')}`}</td>
                        <td className="text-start">{moment(data.createdAt).format('DD MMM YYYY')} / {moment(data.createdAt).format('HH:mm')}</td>
                        <td className="text-center">
                            <PopupViewButton handler={()=>viewDetail(data)} popup='viewContactDetail' />
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/buyer/tour" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/enquiry/take-tour' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>

    <div className="modal fade" id="viewContactDetail" tabIndex="-1" ariaHidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Enquiry Detail</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th width={100}>Name</th>
                                <td>{enquiryData && enquiryData.name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{enquiryData && enquiryData.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{enquiryData && enquiryData.phone}</td>
                            </tr>
                            <tr>
                                <th>Property</th>
                                <td>{enquiryData && enquiryData.property}</td>
                            </tr>
                            <tr>
                                <th>Size</th>
                                <td>{enquiryData && enquiryData.size}</td>
                            </tr>
                            <tr>
                                <th>Unit</th>
                                <td>{enquiryData && enquiryData.unit}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </ContentLayout>
    )
}
