import Ajax from "./Ajax"
const TokenValid = async () => {
    const response = await Ajax({
        url: '/user/validate/login',
        token: true
    })
    if(response.status === 200){
        return true
    }else{
        return false
    }
}
export default TokenValid