import React from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import {useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddValue() {
    const navigate = useNavigate()

    const addProcess = async (values, event) => {
        const data = {
            name: values.value_title, 
            link: values.value_url, 
            sortOrder: values.sort_order, 
            type: values.type === '1' ? 'SELLER' : 'BUYER',
        }
        const update = await Ajax({
            url: `/home/services`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Added Successfully')
            setTimeout(function(){
                navigate('/admin/home/value-added')
            },500)
        }
        console.log(update)
    }
    return (
        <ContentLayout title="New Value Added">
            <Helmet>
                <title>Add New Value Added</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        value_title: '', 
                        value_url: '',
                        sort_order: '',
                        type: '',
                    }}
                    validationSchema={Yup.object({
                        value_title: Yup.string()
                        .required('Field is Required'),
                        value_url: Yup.string()
                        .required('Field is Required'),
                        type: Yup.string()
                        .required('Field is Required'),
                        sort_order: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <FormGroup label="Title" required>
                        <Field placeholder='Title' name="value_title" className='form-control' />
                        <ErrorField><ErrorMessage name="value_title"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Url" required>
                        <Field placeholder='Url' name="value_url" className='form-control' />
                        <ErrorField><ErrorMessage name="value_url"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Sort Order" required>
                        <Field type="number" placeholder='Sort Order' name="sort_order" className='form-control' />
                        <ErrorField><ErrorMessage name="sort_order"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Type" required>
                        <Field as="select" name="type" className='form-select'>
                            <option value="" disabled>Select</option>
                            <option value="1">Seller</option>
                            <option value="2">Buyer</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
