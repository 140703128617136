import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../../UI/Dashboard/Pagination';

export default function LocationList() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/setting/getLocation${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
            setTotalResult(response.data.count)
            setTotalRows(response.data.count / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    return (
    <ContentLayout title='Locations' addNew='/admin/property-management/add-location'>
        <div className="row">
        <Helmet>
            <title>Locations</title>
        </Helmet>
        <DataView title="Locations List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">City</td>
                        <td className="text-start">State</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Edit</td>
                        <td className="text-center">Delete</td>
                     
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={7}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={7} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start text-capitalize">{data.name ? data.name : ''}</td>
                        <td className="text-start text-capitalize">{data.city ? data.city.name : ''}</td>
                        <td className="text-start">{data.state ? data.state.name : ''}</td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/property-management/location/${data._id}`}</EditButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/setting/location" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/property-management/location' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
