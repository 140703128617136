import Ajax from "./Ajax"
const PropertyList = async() => {
    const response = await Ajax({
        url: '/product/allproductList',
        token: true
    })
    return response.data.result
}
export default PropertyList

