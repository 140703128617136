import React from 'react'
import PreLoaderIcon from '../../../assets/images/pre-loader.svg'

export default function PreLoader() {
  return (
    <div className='admin-main-loader'>
        <img src={PreLoaderIcon} alt="loader" />
    </div>
  )
}
