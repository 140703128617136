import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"
import EditButton from '../../../../UI/Dashboard/EditButton'

export default function HowItWorkEdit() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/howItWork?_id=${urlParams.HowItWorkId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateData = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.heading,
            description: values.description,
            sortOrder: values.sortOrder,
            type:'HOME-LOAN'
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/howItWork/${urlParams.HowItWorkId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit How it Work">
            <Helmet>
                <title>Edit  How it Work</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
                initialValues={{ 
                    description: infoDetail.description ? infoDetail.description : '',
                    heading: infoDetail.name ? infoDetail.name : '',
                    sortOrder: infoDetail.sortOrder ? infoDetail.sortOrder : '',
                    type: infoDetail.type ? infoDetail.type : ''
                }}
                validationSchema={Yup.object({
                    description: Yup.string().required('Field is Required'),
                    heading: Yup.string().required('Field is Required'),
                    type: Yup.string().required('Field is Required'),
                    sortOrder: Yup.string().required('Field is Required'),
                   
                })}
                onSubmit={(values) => {
                    updateData(values)
                }}
            >
            <Form>
                <div className='row'>
               
                    <div className='col-md-6'>
                        <FormGroup label="Heading" required>
                            <Field placeholder='Heading' name="heading" className='form-control' />
                            <ErrorField><ErrorMessage name="heading"/></ErrorField>
                        </FormGroup>
                    </div>
                  
                  
                    <div className='col-md-6'>
                        <FormGroup label="Type" required>
                            <Field as="select" name="type"  className='form-select' >
                            <option value='' disabled>Select Type</option>
                            <option value='SELLER'>Seller</option>
                            <option value='BUYER'>Buyer</option>
                            <option value='HOME-LOAN'>VAS - Home Loan</option>
                            <option value='HOME-INTERIOR'>VAS - Home Interior</option>
                            <option value='UNDER-CONTRUCTION'>VAS - Under Construction</option>
                            <option value='BEST-AREA'>VAS - Best Area</option>
                         
                            </Field>
                            <ErrorField><ErrorMessage name="type"/></ErrorField>
                        </FormGroup>
                    </div>
                    <div className='col-md-12'>
                        <FormGroup label="Description" required>
                            <Field as="textarea" name="description"  className='form-control' />
                            <ErrorField><ErrorMessage name="description"/></ErrorField>
                        </FormGroup>
                    </div>
                    <div className='col-md-6'>
                        <FormGroup label="Image" required>
                            <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                        </FormGroup>
                        {infoDetail.file &&
                        <img src={infoDetail.file.thumbnail} alt="prcess icon" height="100" width="100" className='img-fluid mb-4' /> }
                    </div>
                    <div className='col-md-6'>
                        <FormGroup label="Sort Order" required>
                            <Field type="number" placeholder='Sort Order' name="sortOrder" className='form-control' />
                            <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                        </FormGroup>
                    </div>
                </div>
                
                <div className='col-md-2 d-grid'>
                    <Button type="submit">Update</Button>
                </div>
            </Form>
            </Formik> }
            </DataView>
        </ContentLayout>
    )
}
