import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import JourneyAjax from '../../../../../helpers/JourneyAjax'

export default function RMDetails() {
    const urlParams = useParams()
    const navigate = useNavigate()
    const userValidToken = localStorage.getItem("userToken");
    const dataIcon = useRef()
    const [RMDetails, setBrokerDetail] = useState(null)
    const [selectedMicroMarket,setSelectedMicroMarket] = useState()
    const [microMarketOption,setMicroMarketOption] = useState([])

    useEffect(()=>{
            (async()=>{
              const response = await JourneyAjax({
                url: `/relation-managers/${urlParams.RM_Id}`,
                "x-api-key": "houseeazy-dev",
                "x-user": userValidToken || null,
                token: true
              })
              if(response.status === 200){
                setBrokerDetail(response.data)
                if(response && response.data && response.data.microMarket) setSelectedMicroMarket(response.data.microMarket)
              }
              const optionsMm = process.env.REACT_APP_MICRO_MARKET_KEYS || ""
              if(optionsMm){
                const splitList = optionsMm.split(",")
                if(splitList && splitList.length > 0){
                 setMicroMarketOption(splitList)
                }
              }
            })()
      
    },[])

    const updateBroker = async (values, event) => {
        if(!selectedMicroMarket || selectedMicroMarket == "") toast.error('Micro market is required')
        const data = {
            name: values.name, 
            email: values.email, 
            mobile:values.mobile,
            microMarket:selectedMicroMarket || ""
        }
        
        const update = await JourneyAjax({
            url: `/relation-managers/${urlParams.RM_Id}`,
            "x-api-key": "houseeazy-dev",
            "x-user": userValidToken || null,
            method: 'PATCH',
            data:data,
            token: true,
            loader:true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.status === 204){
            toast.success('Relation Manager Updated Successfully')
            setTimeout(function(){
                navigate('/admin/property-journey/relation-manager')
            },500)
        }
    }
    
    const handleMicroMarket = (e) =>{
        setSelectedMicroMarket(e.target.value)
    }
 
    return (
        <ContentLayout title="Edit New Relation Manager">
            <Helmet>
                <title>Edit Broker</title>
            </Helmet>
            <DataView title="Infomation">
                {RMDetails === null ? <DataLoader/> : <Formik
                    initialValues={{ 
                        name: RMDetails&&RMDetails.name, 
                        email:RMDetails&&RMDetails.email,
                        mobile: RMDetails&&RMDetails.mobile,
                        microMarket:selectedMicroMarket
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Name is required"),
                        email: Yup.string()
                          .email("Invalid email")
                          .required("Email is required"),
                        mobile: Yup.string().required("Mobile Number is required"),
                        // microMarket: Yup.string().required("Micro market is required"),
                    })}
                    onSubmit={(values, event) => {
                        updateBroker(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Name" required>
                                <Field placeholder='Name' name="name"  className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup label="Email" required>
                                <Field placeholder='Email' type="email" name="email" className='form-control' />
                                <ErrorField><ErrorMessage name="email"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup label="Mobile" required>
                                <Field placeholder='Mobile Number' type="string" name="mobile" className='form-control' />
                                <ErrorField><ErrorMessage name="mobile"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                <FormGroup label="Micro Market" required>
                <select name="microMarket"  className='form-select' value={selectedMicroMarket} onChange={handleMicroMarket}>
                            <option value="" >Select</option>
                            {microMarketOption && microMarketOption.map((item)=>(
                                <option value={item}>{item}</option>
                            ))}
                    </select>
                  <ErrorField>
                    <ErrorMessage name="microMarket" />
                  </ErrorField>
                </FormGroup>
              </div>
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>
                }
            </DataView>
        </ContentLayout>
    )
}
