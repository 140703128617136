import React from 'react'
import FormGroup from '../Form/FormGroup'

export default function DataLoader({number = 12}) {
    let fields = []
    for(let i = 0; i < number; i++){
        fields = [...fields, {"key": i}]
    }
    return (
    <div className='row'>
        {fields.map((data, index)=>(
            <div className='col-md-3' key={index+'loader-field'}>
                <FormGroup>
                    <div className='field-loader'></div>
                </FormGroup>
            </div>
        ))}
    </div>
    )
}
