import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewAmenity() {
    const urlParams = useParams()
    const navigate = useNavigate()

    const dataIcon = useRef()
    const addProcess = async (values, event) => {
 
        const data = {
            name: values.bhk_title, 
            value: values.bhk_value, 
            parent:0,
            active: true
        }

        const update = await Ajax({
            url: `/product/bhk`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Bhk Added Successfully')
            setTimeout(function(){
                navigate('/admin/property-management/bhk')
            },500)
        }
    }
    return (
        <ContentLayout title="Add New BHK">
            <Helmet>
                <title>Add New BHK</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        bhk_title: '', 
                        bhk_value:'',
                        active: '1',
                    }}
                    validationSchema={Yup.object({
                        bhk_title: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Bhk Title" required>
                                <Field placeholder='Bhk Title' name="bhk_title"  className='form-control' />
                                <ErrorField><ErrorMessage name="bhk_title"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup label="Bhk Value" required>
                                <Field placeholder='Bhk Value' type="number" name="bhk_value" className='form-control' />
                                <ErrorField><ErrorMessage name="bhk_value"/></ErrorField>
                            </FormGroup>
                        </div>
                       
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
