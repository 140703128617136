import React from 'react'
import { toast } from 'react-toastify';
import Ajax from '../../../helpers/Ajax';

export default function DeleteButton({url, id}) {
  const deleteRecord = async () => {
    let confirm = window.confirm("Are you sure want to delete this record ?");
    if(confirm){
      const deleteData = await Ajax({
        url: `${url}/${id}`,
        method: 'DELETE',
        token: true,
        loader: true
      })
    if(deleteData.data.status === "SUCCESS!"){
      toast.success('Record deleted successfully',{
        autoClose: 1000
      })
      setTimeout(function(){
        window.location.reload()
      },500)
    }
    }else{
      return false
    }
  }
  return (
    <button type="button" onClick={()=>deleteRecord()} className="btn btn-danger"><i className="fas fa-trash"></i></button>
  )
}
