import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../../UI/Form/FormGroup'
import ErrorField from '../../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../../UI/Dashboard/DataLoader'
import Button from '../../../../../UI/Form/Button'
import ContentLayout from '../../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function EditHomefestVideo() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/landingPage/review?_id=${urlParams.VideoId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const dataIcon = useRef()
    const addProcess = async (values, event) => {
        //const formData = new FormData()
        const data = {
            title: values.title, 
            youtube: values.youtube, 
            sortOrder: `${values.sortOrder}`,
            active: values.active == 1 ? true : false, 
        }
        // for(var key in data) {
        //     if(data[key] !== "") {
        //         formData.append(key, data[key])
        //     }
        // }
        const update = await Ajax({
            url: `/landingPage/review/${urlParams.VideoId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Video">
            <Helmet>
                <title>Edit Video</title>
            </Helmet>
            <DataView title="Infomation">
                {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        title: infoDetail.title ? infoDetail.title : '', 
                        youtube: infoDetail.youtube ? infoDetail.youtube : '', 
                        sortOrder: infoDetail.sortOrder ? infoDetail.sortOrder : '', 
                        active: infoDetail.active === true ? 1 : 2
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string()
                        .required('Field is Required'),
                        youtube: Yup.string()
                        .required('Field is Required'),
                        active: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Youtube Id" required>
                                <Field placeholder='Youtube Id' name="youtube" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field type="number" placeholder='Sort Order' name="sortOrder" className='form-control' />
                                <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Active" required>
                                <Field as="select" name="active" className='form-select'>
                                    <option value="1">Enable</option>
                                    <option value="2">Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                        {infoDetail.youtube && <div className='col-md-6 mb-4'>
                            <iframe width="400px" src={`https://www.youtube.com/embed/${infoDetail.youtube}?playlist=${infoDetail.youtube}&playsinline=1`} className='' allow="autoplay; encrypted-media; gyroscope"></iframe>
                        </div> }
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
