import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function UserDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/user/getUserList?_id=${urlParams.UserId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
       
        const data = {
            name:values.fullname,
            email:values.email,
            active: values.active == 1 ? true : false, 
        }
       
        const update = await Ajax({
            url: `/user/profile/${urlParams.UserId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('User Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit User">
            <Helmet>
                <title>Edit User</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
               
                    initialValues={{ 
                        fullname: infoDetail.name ? infoDetail.name : '', 
                        email: infoDetail.email ? infoDetail.email : '', 
                        active: infoDetail.active === true ? 1 : 0
                    }}
                    validationSchema={Yup.object({
                        fullname: Yup.string().required('Field is Required'),
                        email: Yup.string().required('Field is Required'),
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'> 
                        <div className='col-md-4'>
                            <FormGroup label="Name" required>
                                <Field placeholder='Name' name="fullname" className='form-control' />
                                <ErrorField><ErrorMessage name="fullname"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Email" required>
                                <Field placeholder='Email' name="email" className='form-control' />
                                <ErrorField><ErrorMessage name="email"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Phone" required>
                                <div className='form-control'>{infoDetail.phoneNumber}</div>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Active" required>
                                <Field as="select" name="active" className='form-select'>
                                    <option value={1}>Enable</option>
                                    <option value={0}>Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
