import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../../UI/Dashboard/Pagination';
import PopupViewButton from '../../../../UI/Dashboard/PopupViewButton';
import moment from 'moment/moment';
import * as XLSX from 'sheetjs-style'
import { toast } from 'react-toastify'

export default function AmenityList() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/career/GetCareerApply${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
            setTotalResult(response.data.countData)
            setTotalRows(response.data.countData / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    const [enquiryData, setEnquiryData] = useState(null)
    const viewDetail = (data) => {
        setEnquiryData({
            name: data.name,
            email: data.email,
            phone: data.phone,
            position: data.jobs.position,
            location: data.jobs.location,
            message: data.message,
            ...(data.file && {resume: data.file.path}),
        })
    }

    const exportCSV = async () => {
        const records = await Ajax({
          url: '/career/GetCareerApply?limit=false',
          token: true,
          loader: true
        })
        if(records.data.result.length === 0) {
            toast.error("Record not found!",{
              autoClose:1500,
            })
            return false
        }
        const excelData = []
        const recordData = records.data.result
        for(var e in recordData){
            excelData.push(
                {
                    "Date": recordData[e].createdAt ? moment(recordData[e].createdAt).format('YYYY-MM-DD / HH:mm') : '',
                    "Name": recordData[e].name ? recordData[e].name : '',
                    "Email": recordData[e].email ? recordData[e].email : '',
                    "Phone": recordData[e].phone ? recordData[e].phone : '',
                    "Position": recordData[e].jobs ? recordData[e].jobs.position : '',
                    "Location": recordData[e].jobs ? recordData[e].jobs.location : '',
                    "Resume": recordData[e].file ? recordData[e].file.path : '',
                }
            )
        }
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Carrer-Job-Enquiry.xlsx");
            
    }

    return (
    <ContentLayout title='Job Apply' exportExcel={['Export CSV', exportCSV]}>
        <div className="row">
        <Helmet>
            <title>Job Apply</title>
        </Helmet>
        <DataView title="Job Apply">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Position</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">Email</td>
                        <td className="text-start">Phone</td>
                        <td className="text-start">Resume</td>
                        <td className="text-start">Date / Time</td>
                        <td className="text-center">View</td>
                        <td className="text-center">Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={9}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={9} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start text-capitalize">{data.jobs?data.jobs.position:''}</td>
                        <td className="text-start text-capitalize">{data.name?data.name:''}</td>
                        <td className="text-start">{data.email?data.email:''}</td>
                        <td className="text-start">{data.phone?data.phone:''}</td>
                        <td className="text-start">
                        {data.file ? 
                        <a href={data.file.path} target="_blank">
                        <i class="fa fa-file fa-4x" aria-hidden="true"></i>
                        </a> : ''}
                           
                        </td>
                        <td className="text-start">{moment(data.createdAt).format('DD MMM YYYY')} / {moment(data.createdAt).format('HH:mm')}</td>
                        <td className="text-center">
                            <PopupViewButton handler={()=>viewDetail(data)} popup='viewCareerDetail' />
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/career/apply" id={data._id} />
                        </td>
                       
                      
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/career/job-apply' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    <div class="modal fade" id="viewCareerDetail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Enquiry Detail</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th width={150}>Name</th>
                                <td>{enquiryData && enquiryData.name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{enquiryData && enquiryData.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{enquiryData && enquiryData.phone}</td>
                            </tr>
                            <tr>
                                <th>Position</th>
                                <td>{enquiryData && enquiryData.position}</td>
                            </tr>
                            <tr>
                                <th>Location</th>
                                <td>{enquiryData && enquiryData.location}</td>
                            </tr>
                            <tr>
                                <th>Message</th>
                                <td>{enquiryData && <div dangerouslySetInnerHTML={{__html: enquiryData.message}} /> }</td>
                            </tr>
                            {enquiryData &&  enquiryData.resume &&
                            <tr>
                                <th>Resume</th>
                                <td><a href={enquiryData.resume} download={enquiryData && enquiryData.name} className="btn btn-primary">Download</a></td>
                            </tr> }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </ContentLayout>
    )
}
