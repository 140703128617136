import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';

export default function AmenityList() {
    const [dataList, setDataList] = useState(null)
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/career/heading?limit=false`,
            token: true,
        })
        console.log(response)
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
        }
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[])

    return (
    <ContentLayout title='Career Headings' >
        <div className="row">
        <Helmet>
            <title>Career Headings</title>
        </Helmet>
        <DataView title="Career Headings">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Culture Title</td>
                        <td className="text-start">Job Title</td>
                        <td className="text-start">Team Title</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Edit</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={6}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={6} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-start">{data.cultureTitle?data.cultureTitle:''}</td>
                        <td className="text-start">{data.jobTitle?data.jobTitle:''}</td>
                        <td className="text-start">{data.teamTitle?data.teamTitle:''}</td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/career/heading/${data._id}`}</EditButton>
                        </td>
                      
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
