import React from 'react'
import { Link } from 'react-router-dom'
import NavItem from '../UI/Dashboard/NavItem'
import { useParams } from 'react-router-dom'

export default function Sidebar() {
    const params = useParams()
  return (
    <nav id="column-left">
        <div id="navigation"><span className="fas fa-bars"></span> Navigation</div>
        <ul id="menu">
            <NavItem url="/admin/dashboard" icon="fas fa-tachometer-alt" active={['/admin/dashboard']}>Dashboard</NavItem>
            
            <NavItem icon="fas fa-home"
                dropdown={[
                    {title: 'Content', url: '/admin/home/content'},
                    {title: 'Process', url: '/admin/home/process'},
                    {title: 'Why Choose Us', url: '/admin/home/why-choose'},
                    {title: 'Value Added Services', url: '/admin/home/value-added'}
                ]}
                active={
                    [
                    '/admin/home/content',
                    `/admin/home/process`,
                    `/admin/home/${params.HomepageId}`,
                    `/admin/home/process/${params.ProcessId}`,
                    `/admin/home/process/add-new`,
                    `/admin/home/why-choose`,
                    `/admin/home/why-choose/${params.WhyChooseId}`,
                    `/admin/home/why-choose/add-new`,
                    `/admin/home/value-added`,
                    `/admin/home/value-added/${params.ValueAddedId}`,
                    `/admin/home/value-added/add-new`,
                    ]
                }
            >
                Home
            </NavItem>
            <NavItem icon="fas fa-building"
                dropdown={[
                    {title: 'Amenities', url: '/admin/property-management/amenities'},
                    {title: 'BHK', url: '/admin/property-management/bhk'},
                    {title: 'Developer', url: '/admin/property-management/developer'},
                    {title: 'Locations', url: '/admin/property-management/location'},
                    {title: 'Sectors', url: '/admin/property-management/sector'},
                    {title: 'Projects', url: '/admin/property-management/projects'},
                    {title: 'ProjectArea', url: '/admin/property-management/projectsarea'},
                    {title: 'Properties', url: '/admin/property-management/properties'},
                    {title: 'Nearby Infra', url: '/admin/property-management/nearby'},
                    // {title: 'Schedules', url: '/admin/property-management/schedule'},
                ]}
                active={
                    [
                    '/admin/property-management/properties',
                    '/admin/property-management/property/add-new',
                    `/admin/property-management/property/${params.PropertyId}`,
                    '/admin/property-management/amenities',
                    '/admin/property-management/add-amenity',
                    `/admin/property-management/amenity/${params.AmenityId}`,
                    '/admin/property-management/bhk',
                    '/admin/property-management/add-bhk',
                    `/admin/property-management/bhk/${params.BhkId}`,
                    '/admin/property-management/nearby',
                    '/admin/property-management/add-nearby',
                    `/admin/property-management/nearby/${params.NearbyId}`,
                    '/admin/property-management/schedule',
                    '/admin/property-management/add-developer',
                    `/admin/property-management/developer/${params.DeveloperId}`,
                    '/admin/property-management/developer',
                    '/admin/property-management/location',
                    '/admin/property-management/add-location',
                    `/admin/property-management/location/${params.LocationId}`,
                    '/admin/property-management/sector',
                    '/admin/property-management/add-sector',
                    `/admin/property-management/sector/${params?.SectorId}`,
                    `/admin/property-management/projectsarea`,
                    `/admin/property-management/projectsarea/${params.ProjectAreaId}`,
                    `/admin/property-management/projectsarea/add`,
                    `/admin/property-management/projects`,
                    `/admin/property-management/project/${params.ProjectId}`,
                    `/admin/property-management/project/add`,
                    ]
                }
            >
                Property Management
            </NavItem>

            <NavItem icon="fas fa-building"
                dropdown={[
                    {title: 'Seller', url: '/admin/property-journey/seller'},
                    {title: 'Buyer', url: '/admin/property-journey/buyer'},
                    {title: 'Broker', url: '/admin/property-journey/broker'},
                    {title: 'Broker Lead', url: '/admin/property-journey/broker-lead'},
                    {title: 'Relation Managers', url: '/admin/property-journey/relation-manager'},
                    {title: 'Aops', url: '/admin/property-journey/aops'}
                ]}
                active={
                    [
                    '/admin/property-journey/seller',
                    `/admin/property-journey/seller/${params.JourneyId}`,
                    '/admin/property-journey/buyer',
                    `/admin/property-journey/buyer/${params.JourneyId}`,
                    `/admin/property-journey/broker`,
                    `/admin/property-journey/broker/${params.BrokerId}`,
                    `/admin/property-journey/broker/add-broker`,
                    `/admin/property-journey/broker-lead`,
                    `/admin/property-journey/broker-lead/${params.BrokerId}/${params.BrokerLeadId}`,
                    `/admin/property-journey/broker-lead/add-broker-lead`,
                    `/admin/property-journey/relation-manager`,
                    `/admin/property-journey/relation-manager/${params.RM_Id}`,
                    `/admin/property-journey/relation-manager/add-rm`,
                    '/admin/property-journey/aops', 
                    `/admin/property-journey/aops/${params.AopId}`,
                    '/admin/property-journey/aops/add-aop',
                    ]
                }
            >
                Property Journey
            </NavItem>

            <NavItem 
                url="/admin/seller-property" 
                icon="fas fa-building" 
                active={[
                    '/admin/seller-property',
                    `/admin/seller/property/${params.PropertyId}`,
                ]}
            >
                Seller Property
            </NavItem>

            <NavItem 
                url="/admin/shortlist-users" 
                icon="fas fa-building" 
                active={[
                    '/admin/shortlist-users',
                    `/admin/shortlist-users/${params.ShortlistId}`,
                ]}
            >
                Shortlist Property Users
            </NavItem>

            <NavItem 
                url="/admin/buyer-list" 
                icon="fas fa-building" 
                active={[
                    '/admin/buyer-list',
                    `/admin/buyer/${params.BuyerId}`,
                ]}
            >
                Upload Doc (Buyer)
            </NavItem>

            <NavItem icon="fa fa-users"
                dropdown={[
                    {title: 'Testimonials', url: '/admin/testimonials'},
                ]}
                active={
                    [
                    '/admin/testimonials',
                    '/admin/add-testimonials',
                    `/admin/testimonials/${params.TestimonialId}`,
                    ]
                }
            >
                Testimonials
            </NavItem>
            <NavItem icon="fa fa-question-circle"
                dropdown={[
                    {title: 'Home Loan',
                        dropdown: [
                            {title: 'Headings', url: '/admin/vas/home-loan'},
                            {title: 'Home Loan Offers', url: '/admin/vas/home-loan-offers'},
                        ]
                    },
                    {title: 'Home Interiors', url: '/admin/vas/home-interior'},
                    {title: 'Under Construction Home', url: '/admin/vas/under-construction-home'},
                    {title: 'Best Area', url: '/admin/vas/best-area'},
                ]}
                active={
                    [
                    `/admin/vas/home-loan`,
                    `/admin/vas/home-loan-offers`,
                    `/admin/vas/add-home-loan-offer`,
                    `/admin/vas/home-loan-offer/${params.LoanOfferId}`,
                    `/admin/vas/home-interior`,
                    `/admin/vas/under-construction-home`,
                    `/admin/vas/best-area`,
                    ]
                }
            >
                VAS Pages
            </NavItem>
            <NavItem icon="fa fa-question-circle"
                dropdown={[
                    {title: 'How It Work', url: '/admin/vas/how-it-work'},
                    {title: 'Whu Choose Us', url: '/admin/vas/why-choose-us'},
                ]}
                active={
                    [
                    '/admin/vas/how-it-work',
                    '/admin/vas/add-how-it-work',
                    `/admin/vas/how-it-work/${params.HowItWorkId}`,
                    '/admin/vas/why-choose-us',
                    '/admin/vas/add-why-choose-us',
                    `/admin/vas/why-choose-us/${params.WhyChooseId}`,
                    ]
                }
            >
                VAS Sections
            </NavItem>
            <NavItem icon="fa fa-question-circle"
                dropdown={[
                    {title: 'Faq', url: '/admin/faq'},                  
                ]}
                active={
                    [
                    '/admin/faq',
                    '/admin/add-faq',
                    `/admin/faq/${params.FaqId}`,
                    ]
                }
            >
                Faq
            </NavItem>
            <NavItem icon="fa fa-users"
                dropdown={[
                    {title: 'Team', url: '/admin/team'},                  
                ]}
                active={
                    [
                    '/admin/team',
                    '/admin/add-team',
                    `/admin/team/${params.TeamId}`,
                    ]
                }
            >
                Team
            </NavItem>

            <NavItem icon="fa fa-blog"
                dropdown={[
                    {title: 'Blog', url: '/admin/blog'},
                    {title: 'Events', url: '/admin/events'},
                    {title: 'Awards', url: '/admin/awards'},
                ]}
                active={
                    [
                    '/admin/blog',
                    '/admin/add-blog',
                    `/admin/blog/${params.BlogId}`,
                    '/admin/events',
                    '/admin/awards',
                    '/admin/add-event',
                    ]
                }
            >
                Blog And Media
            </NavItem>

            <NavItem icon="fas fa-users"
                dropdown={[
                    {title: 'Heading', url: '/admin/about/content'},
                    {title: 'Gallery', url: '/admin/about/gallery'},
                    {title: 'Solutions Superior', url: '/admin/about/solutions-superior'},
                  
                ]}
                active={
                    [
                        '/admin/about/content',
                         `/admin/employee/${params.EmployeeId}`,
                         '/admin/about/gallery', 
                         '/admin/about/add-gallery', 
                         `/admin/about/gallery/${params.GalleryId}`,
                         '/admin/about/solutions-superior', 
                         '/admin/about/add-solutions-superior', 
                         `/admin/about/solutions-superior/${params.SolutionId}`,
                        
                        ]
                }
            >
                About
            </NavItem>

            

            <NavItem icon="fas fa-info-circle"
                dropdown={[
                    {title: 'Heading', url: '/admin/career/heading'},
                    {title: 'Gallery', url: '/admin/career/gallery'},
                    {title: 'Job Opening', url: '/admin/career/job'},
                    {title: 'Job Apply', url: '/admin/career/job-apply'},
                    {title: 'Career Enquiry', url: '/admin/career/job-enquiry'},
                  
                ]}
                active={
                    [
                    '/admin/career/gallery', 
                    '/admin/career/add-gallery', 
                    `/admin/career/gallery/${params.GalleryId}`,
                    '/admin/career/job',
                    '/admin/career/add-job',
                    `/admin/career/job/${params.JobId}`,
                    '/admin/career/job-enquiry',
                    '/admin/career/job-apply',
                    '/admin/career/heading',
                    `/admin/about/${params.AboutPageId}`,
                    `/admin/career/heading/${params.HeadingId}`,
                ]
                }
            >
                Career
            </NavItem>

            {/* <NavItem icon="fa fa-home"
                dropdown={[
                    {title: 'Heading', url: '/admin/employee/list'},  
                    {title: 'Home Loans Offer', url: '#'},
                    {title: 'Why Apply Us', url: '/admin/employee/list'},   
                    {title: 'Loan Tips', url: '/admin/employee/list'},                   
                ]}
                active={
                    ['/admin/employee/list', `/admin/employee/${params.EmployeeId}`]
                }
            >
                Finance
            </NavItem>

            <NavItem icon="fa fa-home"
                dropdown={[
                    {title: 'Heading', url: '/admin/employee/list'},                  
                ]}
                active={
                    ['/admin/employee/list', `/admin/employee/${params.EmployeeId}`]
                }
            >
                Channel Partner
            </NavItem> */}

            <NavItem icon="fas fa-file" 
                active={[
                    '/admin/page/homefest/headings',
                    '/admin/page/homefest/features',
                    '/admin/page/homefest/feature/add-new',
                    '/admin/page/homefest/videos',
                    '/admin/page/homefest/video/add-new',
                    `/admin/page/homefest/video/${params.VideoId}`,
                    `/admin/page/homefest/feature/${params.FeatureId}`,
                ]}
                dropdown={[
                    {title: 'Homefest',
                    dropdown:[
                        {title: 'Headings', url: '/admin/page/homefest/headings'},
                        {title: 'Features', url: '/admin/page/homefest/features'},
                        {title: 'Videos', url: '/admin/page/homefest/videos'},
                    ]
                    }
                ]}
            >
                Pages 
            </NavItem>

            <NavItem icon="fas fa-users" 
                active={[
                    '/admin/user/list',
                    `/admin/user/${params.UserId}`,
                    '/admin/user/buyer/schedule',
                    '/admin/user/seller/schedule',
                    
                ]}
                dropdown={[
                    {title: 'User List', url: '/admin/user/list'},
                    {title: 'Buyer Schedule List', url: '/admin/user/buyer/schedule'},
                    {title: 'Seller Schedule List', url: '/admin/user/seller/schedule'},
                ]}
            >
                Client 
            </NavItem>
            

            <NavItem icon="fas fa-cog"
                dropdown={[
                    {title: 'Homepage', url: '/admin/enquiry/homepage'},
                    {title: 'Property', url: '/admin/enquiry/property'},
                    {title: 'Home Loan', url: '/admin/enquiry/home-loan'},
                    {title: 'Channel Partner', url: '/admin/enquiry/channel-partner'},
                    {title: 'Newsletter', url: '/admin/enquiry/newsletter'},
                    {title: 'Contact Us', url: '/admin/enquiry/contact-us'},
                    {title: 'Lead Enqiry', url: '/admin/enquiry/lead'},
                    {title: 'Homefest', url: '/admin/enquiry/homefest'},
                    {title: 'Popup CTA', url: '/admin/enquiry/popup-cta'},
                    {title: 'Take Tour', url: '/admin/enquiry/take-tour'},
                ]}
                active={
                    [
                        '/admin/enquiry/newsletter',
                        '/admin/enquiry/contact-us',
                        '/admin/enquiry/property',
                        '/admin/enquiry/home-loan',
                        '/admin/enquiry/channel-partner',
                        '/admin/enquiry/homepage',
                        '/admin/enquiry/lead',
                        '/admin/enquiry/homefest',
                        '/admin/enquiry/popup-cta',
                        '/admin/enquiry/take-tour',
                    ]
                }
            >
                Enquiry
            </NavItem>

            <NavItem icon="fas fa-cog"
                dropdown={[
                    {title: 'Setting', url: '/admin/setting/general'},
                    {title: 'Meta', url: '/admin/setting/meta'},
                     {
                        title: 'User', 
                        url: '#',
                        dropdown: [
                            {title: 'Users', url: '#'},
                            {title: 'Users Groups', url: '#'}
                        ]
                    },
                    {title: 'App Config', url: '/admin/setting/configList'},                  
                    {title: 'Data Analytics', url: '/admin/setting/dataAnalytics'},
                ]}
                active={
                    ['/admin/setting/general', '/admin/setting/meta', `/admin/setting/meta/${params.MetaId}`]
                }
            >
                System
            </NavItem>

            {/* <NavItem icon="fas fa-shopping-cart"
                dropdown={[
                    {title: 'Orders', url: '#'}
                ]}
            >
            Sales
            </NavItem>

            <NavItem icon="fas fa-user"
                dropdown={[
                    {title: 'Customers', url: '#'}
                ]}
            >
            Customers
            </NavItem>

            <NavItem icon="fas fa-cog"
                dropdown={[
                    {title: 'Settings', url: '#'},
                    {title: 'Users', url: '#'}
                ]}
            >
            System
            </NavItem> */}
        </ul>
    </nav>
  )
}
