import React, {useEffect, useState, useContext} from 'react'
import Ajax from '../../../../helpers/Ajax'
import Button from '../../../UI/Form/Button'
import FormGroup from '../../../UI/Form/FormGroup'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Pagination from '../../../UI/Dashboard/Pagination';
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import ViewButton from '../../../UI/Dashboard/ViewButton';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import FilterOptions from '../../../../helpers/FilterOptions';
import FilterLayout from '../../../UI/Dashboard/FilterLayout';
import ContentLayout from '../../../Common/ContentLayout';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import moment from 'moment/moment';
import * as XLSX from 'sheetjs-style'

export default function SellerPropertyList() {
    const [service, setService] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/seller/property${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}${Object.keys(filterKey).length === 0 ? '' : `&${new URLSearchParams(filterKey)}`}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setService(response.data.result)
            setTotalResult(response.data.count)
            setTotalRows(response.data.count / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
        
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    //Filters
    const [filterKey, setFilterKey] = useState({})
    const setFilter = (e) => {
        const fieldName = e.target.getAttribute("name")
        let updatedValue = {};
        if(e.target.value.trim().length === 0) {
            delete filterKey[fieldName]
            return false
        }
        updatedValue[fieldName] = e.target.value.trim()
        setFilterKey(oldValue => ({...oldValue, ...updatedValue}));
    }

    const filterHandler = async() => {
        const filterResponse = await Ajax({
            url: '/product?' + new URLSearchParams(filterKey),
            token: true,
            loader: true
        })
        if(filterResponse.data.result.length === 0){
            setService(filterResponse.data.result)
            toast.error('Matching Record not found!',{
                autoClose: 1500
            })
            setSearch(true)
        }else{
            setCurrentPage(0)
            setInitialPage(0)
            setService(filterResponse.data.result)
            setTotalRows(filterResponse.data.countData / 10)
            setTotalResult(filterResponse.data.countData)
            pageParams && navigate('/admin/home/service')
            return false
        }
    }

    const exportCSV = async () => {
        const records = await Ajax({
          url: '/seller/property?limit=false',
          token: true,
          loader: true
        })
        if(records.data.result.length === 0) {
            toast.error("Record not found!",{
              autoClose:1500,
            })
            return false
        }
        const excelData = []
        const recordData = records.data.result
        for(var e in recordData){
            excelData.push(
                {
                    "Date": recordData[e].createdAt ? moment(recordData[e].createdAt).format('YYYY-MM-DD / HH:mm') : '',
                    "Society": recordData[e].societyName ? recordData[e].societyName.name : '',
                    "State": recordData[e].state && recordData[e].state.name ? recordData[e].state.name : '',
                    "City": recordData[e].city && recordData[e].city.name ? recordData[e].city.name : '',
                    "Unit Number": recordData[e].unitNumber ? recordData[e].unitNumber : '',
                    "Area": recordData[e].area ? recordData[e].area.area : '',
                    "Furnishing": recordData[e].furnishing && recordData[e].furnishing.name ? recordData[e].furnishing.name : '',
                    "Post By": recordData[e].postingBy ? recordData[e].postingBy : '',
                    "User Name": recordData[e].user && recordData[e].user.name ? recordData[e].user.name : '',
                    "User Email": recordData[e].user && recordData[e].user.email ? recordData[e].user.email : '',
                    "User Phone": recordData[e].user && recordData[e].user.phoneNumber ? recordData[e].user.phoneNumber : '',
                    "Status": recordData[e].active === true ? 'Active' : 'Inactive'
                }
            )
        }
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Seller-Property.xlsx");
            
    }

    return (
    <ContentLayout title='Seller Property List' exportExcel={['Export CSV', exportCSV]}>
        <div className="row">
        <Helmet>
            <title>Seller Property List</title>
        </Helmet>
        {/* {service == null ? '' : (service.length > 0 || search === true) &&
        <FilterLayout>
            <div className='row'>
                <div className='col-md-3'>
                    <FormGroup label="Employee Name (First Name)">
                        <input type="text" name="firstName" placeholder="Employee Name" onChange={setFilter} className="form-control" />
                    </FormGroup>
                </div>
                <div className='col-md-3'>
                    <FormGroup label="E-Mail">
                        <input type="text" name="email" placeholder="E-Mail" className="form-control" onChange={setFilter}  />
                    </FormGroup>
                </div>
                <div className='col-md-3'>
                    <FormGroup label="Employee Code">
                        <input type="number" name="empCode" placeholder="Employee Code" className="form-control" onChange={setFilter}  />
                    </FormGroup>
                </div>
            </div>
            <div className="col-md-2 d-grid">
                <Button type="button" icon="fas fa-filter" onclick={filterHandler}>Filter</Button>
            </div>
        </FilterLayout> } */}
        <DataView title="Property List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start" width={250}>Society</td>
                        <td className="text-start">Unit</td>
                        <td className="text-center">Area</td>
                        <td className="text-center">Post By</td>
                        <td className="text-center">User</td>
                        <td className="text-center">State</td>
                        <td className="text-center">Phone</td>
                        <td className="text-center" width={100}>Date / Time</td>
                        <td className="text-center">Status</td>
                        <td className="text-center">View</td> 
                        <td className="text-center">Delete</td> 
                    </tr>
                </thead>
                <tbody>
                    {service == null ? 
                    <TableLoader cols={12}/> : service.length === 0 ? 
                    <tr>
                        <td colSpan={12} className="text-center">Data not available</td>
                    </tr> : service.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.societyName ? data.societyName.name : 'NA'}</td>
                        <td className="text-start">{data.unitNumber}</td>
                        <td className="text-center">{data.area ? data.area.area : ''}</td>
                        <td className="text-center">{data.postingBy}</td>
                        <td className="text-center">{data.user && data.user.name ? data.user.name : 'NA'}</td>
                        <td className="text-center">{data.state ? data.state.name : 'NA'}</td>
                        <td className="text-center">{data.user ? data.user.phoneNumber : 'NA'}</td>
                        <td className="text-center">{moment(data.createdAt).format('DD MMM YYYY')}<br/>{moment(data.createdAt).format('HH:mm')}</td>
                        <td className="text-center">
                            {data.active === true ? 
                            <span className="badge bg-success">Active</span> : 
                            <span className="badge bg-danger">Disable</span>
                            }
                        </td> 
                        <td className="text-center">
                            <ViewButton>{`/admin/seller/property/${data._id}`}</ViewButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/seller/property" id={data._id}/>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/seller-property' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
