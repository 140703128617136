import React, {useContext} from 'react'
import Layout from '../../Common/Layout';
import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  return (
    <Layout title='Dashboard'>
          <Outlet />
    </Layout>
  )
}
