import React, {useContext} from 'react'
import LogoImage from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import Logout from '../../helpers/Logout'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../store/auth'

export default function Header() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const LogoutAccount = async () => {
    const isLogout = await Logout()
    if(isLogout){
        auth.setLogin(false)
        document.cookie = "token='';path=/;expires=Thu, 18 Dec 2013 12:00:00 UTC";
        document.cookie = "houseeazy='';path=/;expires=Thu, 18 Dec 2013 12:00:00 UTC";
        navigate('/')
    }
  }
  return (
    <header id="header" className="navbar navbar-expand navbar-light bg-light">
        <div className="container-fluid">
            <Link to="/admin/dashboard" className="navbar-brand d-none d-lg-block img-fluid">
                <img src={LogoImage} alt="OpenCart" title="OpenCart" />
            </Link>
            <button type="button" id="button-menu" className="btn btn-link d-inline-block d-lg-none">
                <i className="fas fa-bars"></i>
            </button>
            <ul className="nav navbar-nav">
                <li id="header-profile" className="nav-item dropdown">
                    <span role="button" data-bs-toggle="dropdown" className="nav-link dropdown-toggle">
                        <img src={process.env.PUBLIC_URL + '/images/profile-icon.png'} alt="demo demo" title="demo" className="rounded-circle" />
                        <span className="d-none d-md-inline d-lg-inline"> demo demo 
                            <i className="fas fa-caret-down fa-fw"></i>
                        </span>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                            <Link to="#" className="dropdown-item"><i className="fa fa-user-circle fa-fw"></i> Your Profile</Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <a href={process.env.REACT_APP_WEBSITE} target="_blank" rel="noreferrer" className="dropdown-item">
                            <i className="fa fa-globe fa-fw"></i> Website
                        </a>
                    </ul>
                </li>
                <li id="header-logout" className="nav-item" onClick={LogoutAccount}>
                    <span className="nav-link" role="button">
                        <i className="fas fa-sign-out-alt"></i> 
                        <span className="d-none d-md-inline">Logout</span>
                    </span>
                </li>
            </ul>
        </div>
    </header>
  )
}
