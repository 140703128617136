import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";

export default function AopDetails() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const [RMDetails, setBrokerDetail] = useState(null);

  useEffect(() => {
    const getResponse = setTimeout(function() {
      (async () => {
        const response = await JourneyAjax({
          url: `/aops/${urlParams.AopId}`,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          token: true,
        });
        if (response.status === 200) {
          setBrokerDetail(response.data);
        }
      })();
    }, 100);

    return () => {
      clearInterval(getResponse);
    };
  }, []);

  const updateBroker = async (values, event) => {
    const data = {
      name: values.name,
      value: values.value,
      status: values.status,
    };

    if (values.validTill)
      data.validTill = new Date(values.validTill).toISOString();

    const update = await JourneyAjax({
      url: `/aops/${urlParams.AopId}`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      method: "PATCH",
      data: data,
      token: true,
      loader:true
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 204) {
      toast.success("Aop Updated Successfully");
      setTimeout(function() {
        navigate("/admin/property-journey/aops");
      }, 500);
    }
  };

  return (
    <ContentLayout title="Edit Aop">
      <Helmet>
        <title>Edit Broker</title>
      </Helmet>
      <DataView title="Infomation">
        {RMDetails === null ? (
          <DataLoader />
        ) : (
          <Formik
            initialValues={{
              name: RMDetails && RMDetails.name,
              value: RMDetails && RMDetails.value,
              status: RMDetails && RMDetails.status,
              validTill:RMDetails &&
              RMDetails.validTill &&
              new Date(RMDetails && RMDetails.validTill ).toISOString().split('T')[0],
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Field is Required"),
            })}
            onSubmit={(values, event) => {
              updateBroker(values, event);
            }}
          >
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup label="Name" required>
                    <Field
                      placeholder="Name"
                      name="name"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="name" />
                    </ErrorField>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup label="Value" required>
                    <Field
                      placeholder="Value"
                      type="number"
                      name="value"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="value" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="status" required>
                    <Field as="select" name="status" className="form-select">
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                    <ErrorField>
                      <ErrorMessage name="status" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                <FormGroup label="Valid Till" required>
                  <Field
                    type="date"
                    placeholder="Valid Till"
                    name="validTill"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="validTill" />
                  </ErrorField>
                </FormGroup>
              </div>
              </div>

              <div className="col-md-2 d-grid">
                <Button type="submit">Update</Button>
              </div>
            </Form>
          </Formik>
        )}
      </DataView>
    </ContentLayout>
  );
}
