import React, { useState, useEffect, useRef } from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../../helpers/GetState'
import GetCity from '../../../../../helpers/GetCity'
import GetLocation from '../../../../../helpers/GetLocation'
import GetSector from '../../../../../helpers/GetSector'

export default function AddProject() {
    const navigate = useNavigate()
    const [states, setStates] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [sector, setSector] = useState(null)
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')
    const [selectedSector,setSelectedSector] = useState('')
    const [amenitiesRating, setAmenitiesRating] = useState()
    const [connectivityRating, setConnectivityRating] = useState()
    const [constructionRating, setConstructionRating] = useState()
    const [managementRating, setManagementRating] = useState()
    const [greenAreaRating, setGreenAreaRating] = useState()

    useEffect(() => {
        (async () => {
            const stateList = await GetState()
            setStates(stateList)
        })();
        // (async()=>{
        //     const productInfo = await Ajax({
        //         url: `/project/list?_id=${urlParams.ProjectId}`,
        //         token: true
        //     })
        //     if(productInfo.data.status === 'SUCCESS!'){
        //         setProductDetail(productInfo.data.result[0])
        //         setSelectedState(productInfo.data.result[0].state._id)
        //         setSelectedCity(productInfo.data.result[0].city._id)
        //         const cityFetch = await GetCity(productInfo.data.result[0].state._id)
        //         setCity(cityFetch)
        //     }
        // })()
    }, [])

    //Add Product
    const addProduct = async (values, event) => {
        let societyRating = []
        if(amenitiesRating && connectivityRating && constructionRating && managementRating && greenAreaRating){
            societyRating = [
                {title:"Amenities",rating:amenitiesRating},
                {title:"Construction",rating:constructionRating},
                {title:"Connectivity",rating:connectivityRating},
                {title:"Management",rating:managementRating},
                {title:"Green Area",rating:greenAreaRating}
            ]
        }else{
            toast.error('All ratings are require', {
                toastId: 'ratingError'
            })
            return false
        }
        const data = {
            name: values.project_name,
            state: selectedState,
            city: selectedCity,
            location: selectedLocation,
            societyRating:societyRating
            //active: values.active == 1 ? true : false
        }
        if (selectedState === '') {
            toast.error('Please Select State', {
                toastId: 'StateError'
            })
            return false
        }
        if (selectedCity === '') {
            toast.error('Please Select City', {
                toastId: 'CityError'
            })
            return false
        }
        if (selectedLocation === '') {
            toast.error('Please Select location', {
                toastId: 'LocationError'
            })
            return false
        }
        // if (selectedSector === '') {
        //     toast.error('Please Select sector', {
        //         toastId: 'SectorError'
        //     })
        //     return false
        // }
        if(selectedSector || selectedSector !== "") data.sector = selectedSector
        const update = await Ajax({
            url: `/project`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if (update.data.error) {
            toast.error('Something Went Wrong Please Check')
        }
        if (update.data.status === "SUCCESS!") {
            toast.success('Project added successfully')
            setTimeout(function () {
                navigate('/admin/property-management/projects')
            }, 500)
        }
    }

    //Seelct State for City
    const chooseState = async(e) => {
        var stateId = e.target.value
        setSelectedState(stateId)
        setSelectedCity('')
        setSelectedLocation('')
        setSelectedSector('')
        const cityFetch = await GetCity(stateId, true)
        setCity(cityFetch)
    }

    //Select City for Location
    const chooseCity = async(e) => {
        var cityId = e.target.value
        setSelectedCity(cityId)
        setSelectedLocation('')
        setSelectedSector('')
        const locationFetch = await GetLocation(cityId, true)
        setLocation(locationFetch)
    }

    //Select Location for sector
    const chooseLocation = async(e) => {
        var locationId = e.target.value
        setSelectedLocation(locationId)
        setSelectedSector('')
        const sectorFetch = await GetSector(locationId, true)
        setSector(sectorFetch)
    }
 
    const checkRatingValue = (e) =>{
        const rating = parseFloat(e.target.value)
        if(rating < 1 || rating > 5){
            toast.error('Rating must be between  1 to 5', {
                toastId: 'ratingError'
            })
            return false
        }
        if(e.target.name == "amenities"){
          setAmenitiesRating(rating)
        }else if(e.target.name == "construction"){
            setConstructionRating(rating)
        }else if(e.target.name == "connectivity"){
            setConnectivityRating(rating)
        }else if(e.target.name == "management"){
            setManagementRating(rating)
        }else if(e.target.name == "green_area"){
            setGreenAreaRating(rating)
        }
    }
    return (
        <ContentLayout title="Add New Project">
            <Helmet>
                <title>Add New Project</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{
                        project_name: '',
                        //active: 1
                    }}
                    // validationSchema={Yup.object({
                    //     process_title: Yup.string()
                    //     .required('Field is Required'),
                    //     process_description: Yup.string()
                    //     .required('Field is Required'),
                    //     sort_order: Yup.string()
                    //     .required('Field is Required'),
                    //     type: Yup.string()
                    //     .required('Field is Required')
                    // })}
                    onSubmit={(values, event) => {
                        addProduct(values, event)
                    }}
                >
                    <Form>
                        <FormGroup label="Project Name" required>
                            <Field placeholder='Project Name' name="project_name" className='form-control' />
                            <ErrorField><ErrorMessage name="project_name" /></ErrorField>
                        </FormGroup>
                        <div class="row">
                            <div class="col-md-6">
                                <FormGroup label="State" required>
                                    <select name="state" className='form-select' value={selectedState} onChange={chooseState}>
                                        <option value="" disabled>Select</option>
                                        {states === null ? <option disabled>Loading</option> : states.map((data) => (
                                            <option key={data._id} value={data._id}>{data.name}</option>
                                        ))}
                                    </select>
                                    <ErrorField><ErrorMessage name="state" /></ErrorField>
                                </FormGroup>
                            </div>
                            <div class="col-md-6">
                                <FormGroup label="City" required>
                                    <select name="city" value={selectedCity} className='form-select' onChange={chooseCity}>
                                        <option value="" disabled>Select</option>
                                        {city === null ? <option disabled>Select State</option> : city.map((data) => (
                                            <option key={data._id} value={data._id}>{data.name}</option>
                                        ))}
                                    </select>
                                    <ErrorField><ErrorMessage name="city" /></ErrorField>
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Location" required>
                                <select name="locationId" value={selectedLocation} className='form-select' onChange={chooseLocation}>
                                            <option value="" disabled>Select</option>
                                            {location == null ? <option disabled>Loading</option> : location.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="locationId"/></ErrorField>
                                    </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sector" >
                                <select name="sector" value={selectedSector} onChange={(e)=>setSelectedSector(e.target.value)} className='form-select'>
                                    <option value="">None</option>
                                        {sector == null ? <option disabled>Loading</option> : sector?.map((data)=>(
                                            <option key={data._id} value={data._id}>{data.name}</option>
                                        ))}
                                    </select>
                            <ErrorField><ErrorMessage name="sector"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>

                        <h5><b>Society Rating</b></h5>
                        <div class="row">
                            <div class="col-md-4">
                                <FormGroup label="Amenities" required>
                                    <Field type="number" value={amenitiesRating} placeholder='Amenities Rating' name="amenities"   className='form-control' onChange={checkRatingValue}/>
                                    <ErrorField><ErrorMessage name="amenities" /></ErrorField>
                                </FormGroup>
                            </div>
                            <div class="col-md-4">
                                <FormGroup label="Construction" required>
                                    <Field  type="number" value={constructionRating}  placeholder='Construction Rating' name="construction" className='form-control' onChange={checkRatingValue}/>
                                    <ErrorField><ErrorMessage name="construction" /></ErrorField>
                                </FormGroup>
                            </div>
                            <div class="col-md-4">
                                <FormGroup label="Connectivity" required>
                                    <Field type="number" value={connectivityRating}  placeholder='Connectivity Rating' name="connectivity" className='form-control' onChange={checkRatingValue}/>
                                    <ErrorField><ErrorMessage name="connectivity" /></ErrorField>
                                </FormGroup>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <FormGroup label="Management" required>
                                    <Field  type="number" value={managementRating}  placeholder='Management Rating' name="management" className='form-control' onChange={checkRatingValue}/>
                                    <ErrorField><ErrorMessage name="management" /></ErrorField>
                                </FormGroup>
                            </div>
                            <div class="col-md-6">
                                <FormGroup label="Green Area" required>
                                    <Field type="number" value={greenAreaRating} placeholder='Green Area Rating' name="green_area" className='form-control' onChange={checkRatingValue}/>
                                    <ErrorField><ErrorMessage name="green_area" /></ErrorField>
                                </FormGroup>
                            </div>
                        </div>
                        {/* <FormGroup label="Active" required>
                        <Field as="select" type="number" name="active" className='form-select'>
                            <option value={1}>Enable</option>
                            <option value={0}>Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="active"/></ErrorField>
                    </FormGroup> */}
                        <div className='col-md-2 d-grid'>
                            <Button type="submit">Add Project</Button>
                        </div>
                    </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}