import React, { useState, useEffect } from 'react'
import axios from "axios"

export default function HomeScreenBanner({data, target, action, label, value,propertyName,propertyId,isProperty,id}) {
    const [slug,setSlug] = useState(label)
    const [propertyCheck,setPropertyCheck] = useState(id?isProperty:false)
    const [url,setUrl] = useState(value)
    useEffect(()=>{
        (async () => {
            action(current =>{
                current.map((item)=>{
                    if(item.key == target){
                        item.key = target
                        item.url = url || ""
                        item.slug = slug || ""
                        item.propertyName = propertyName
                        item.propertyId = propertyId
                        item.isProperty = propertyCheck
                    }
                    return item
                })
            return current
            })
          })()
    })
    const deleteRow = () => {
        let deleteConfirm = window.confirm("Are you sure want to delete ?")
        if(deleteConfirm === true) {
            action(current =>
                current.filter(data => {
                    return data.key !== target;
                })
            )
        }
    }
    const upload = async (e) => {
        document.querySelector(".form-loader").classList.add("show")
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("file", file);
        const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
        const reqHeaders = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const response = await axios.post(URL, formData, reqHeaders);
        const uploadDocRes = response.data;
        console.log(uploadDocRes,"uploadDocRes")
        if(uploadDocRes && uploadDocRes.length >0){
           setUrl(uploadDocRes[0])
        }
        document.querySelector(".form-loader").classList.remove("show")
    }

   
    return (
        <tr>
            <td className="text-start">
                <input type="hidden" name="key" defaultValue={target ? target : ''} />  
                <input type="hidden" name="propertyName" defaultValue={propertyName ? propertyName : ''} />  
                <input type="hidden" name="propertyId" defaultValue={propertyId ? propertyId : ''} /> 

                <input type="text" name="slug" defaultValue={label ? label : ''} onChange={(e)=>setSlug(e.target.value)} placeholder="Slug" className="form-control" />
            </td>
            <td className="text-start">
                <div className="input-group">
                    <input type="file" onChange={upload} name="homeBannerImage" className="form-control" />
                </div> 
             {url && 
                <div>
                    <img src={url} width={80} height={50}/>
                </div>
                }
            </td>
            <td >
                <center>
                    <input type="checkbox" name="isProperty" onChange={(e)=>setPropertyCheck(e.target.checked)} defaultChecked={id ? isProperty : false} style={{"width":"30px","height":"30px"}} className="form-check-input" />
                </center>
            </td>
            <td className="text-end">
                <button type="button" onClick={deleteRow} className="btn btn-danger">
                    <i className="fas fa-minus-circle"></i>
                </button>
            </td>
        </tr>
    )
}