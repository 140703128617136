import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

export default function Layout({title, children}) {
  return (
    <HelmetProvider>
    <div id="container">
        <Header/>
        <Sidebar/>
            {children}
        <Footer/>
    </div>
    <Helmet>
    <script src={process.env.PUBLIC_URL + "/js/bootstrap.bundle.min.js" } />
    </Helmet>
    </HelmetProvider>
  )
}
