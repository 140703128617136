import React, {useEffect, useContext} from 'react'
import AccountLayout from '../../Common/AccountLayout';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../UI/Form/FormGroup';
import ErrorField from '../../UI/Form/ErrorField';
import { Link, useNavigate  } from 'react-router-dom';
import Button from '../../UI/Form/Button';
import Ajax from '../../../helpers/Ajax';
import { toast } from 'react-toastify';
import AuthContext from '../../../store/auth';
export default function Login() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext)
    useEffect(()=>{
      if(auth.isLoggedIn === true){
        navigate('/admin/dashboard')
      }
    },[])

    const loginAccount = async(values) => {
    const data = {
        method: 'POST',
        url: '/user/login',
        data: {
            email: values.email,
            password: values.password
        },
        loader: true
    }
    const response = await Ajax(data)
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + Number(process.env.REACT_APP_SESSION_TIME))
    if(response.data.status === "SUCCESS!"){
        document.cookie = `token=${response.data.result.token};path=/;expires=${tomorrow}`;
        toast.success('Login successfully !',{
            autoClose: 1500,
            toastId: 'login-error'
        })
        //navigate("/admin/dashboard")
        window.location.href = `${process.env.REACT_APP_DOCUSIGN_URL}oauth/auth?response_type=code&scope=signature%20impersonation&client_id=${process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY}&redirect_uri=${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}`
    }
    if(response.data.error){
        toast.error(response.data.error, {
            autoClose: 1500,
            toastId: 'login-error'
        })
        return false
    }
    return true
  }
  return (
    <AccountLayout title="Please enter your login details.">
        <Formik
            initialValues={{ email: '', password: ''}}
            validationSchema={Yup.object({
                email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
                password: Yup.string()
                .min(6, 'Must be 6 characters')
                .required('Required'),
            })}
            onSubmit={(values) => {
                loginAccount(values)
            }}
            >
            <Form>
                <FormGroup label="Email" icon="fas fa-envelope">
                    <Field name="email" type="email" className="form-control" placeholder="Email" />
                    <ErrorField><ErrorMessage name="email" /></ErrorField>
                </FormGroup>
                <FormGroup label="Password" icon="fas fa-lock">
                    <Field name="password" type="password" className="form-control" placeholder="Password" />
                    <ErrorField><ErrorMessage name="password" /></ErrorField>
                </FormGroup>
                <div className="mb-3">
                    <Link to="/forgot-password">Forgot Password ?</Link>
                </div>
                <div className="d-grid">
                    <Button type="submit">Login</Button>
                </div>
            </Form>
        </Formik>
    </AccountLayout>
  )
}