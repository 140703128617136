import React from "react";

const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#D5D5D5"
      d="M1.691 9.148c0 .936.171 1.814.513 2.634.342.82.816 1.542 1.423 2.166a6.661 6.661 0 0 0 2.106 1.464 6.27 6.27 0 0 0 2.56.528c.718 0 1.402-.114 2.053-.34a6.76 6.76 0 0 0 1.787-.937l4.07 4.196a.991.991 0 0 0 .703.29.93.93 0 0 0 .497-.132.908.908 0 0 0 .335-.362c.08-.153.12-.324.12-.51 0-.137-.023-.265-.07-.384a.942.942 0 0 0-.203-.315L13.54 13.26c.425-.567.757-1.2.997-1.898a6.76 6.76 0 0 0 .36-2.213c0-.936-.17-1.814-.513-2.634a6.873 6.873 0 0 0-1.423-2.166 6.661 6.661 0 0 0-2.106-1.464 6.27 6.27 0 0 0-2.56-.528 6.27 6.27 0 0 0-2.561.528 6.66 6.66 0 0 0-2.106 1.464 6.872 6.872 0 0 0-1.423 2.166 6.773 6.773 0 0 0-.513 2.634Zm1.415 0a5.348 5.348 0 0 1 3.173-4.923 4.95 4.95 0 0 1 2.015-.413 4.95 4.95 0 0 1 2.014.413 5.164 5.164 0 0 1 1.65 1.149c.475.49.848 1.058 1.118 1.702a5.3 5.3 0 0 1 .405 2.072 5.3 5.3 0 0 1-.405 2.073 5.42 5.42 0 0 1-1.117 1.697 5.254 5.254 0 0 1-1.65 1.15 4.908 4.908 0 0 1-2.015.417 4.908 4.908 0 0 1-2.015-.417 5.327 5.327 0 0 1-3.173-4.92Z"
    />
  </svg>
);

export default SearchIcon;
