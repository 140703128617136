import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewAmenity() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
      
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])




    const addProcess = async (values, event) => {
      
        const data = {
            name:values.name,
            sortOrder:values.sortOrder,
            type:values.type,
            active: values.active == 1 ? true : false, 
        }
        
        const update = await Ajax({
            url: `/about/solution`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Solution Superior Added Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Add Solution Superior">
            <Helmet>
                <title>Add Solution Superior</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                    
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Field is Required'),
                        sortOrder: Yup.number().required('Field is Required'),
                        type: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                    <div className='col-md-4'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-4'>
                            <FormGroup label="Sort Order" required>
                                <Field placeholder='Sort Order' type="number" name="sortOrder" className='form-control' />
                                <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                            </FormGroup>
                        </div>
                      
                      
                        <div className='col-md-4'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type"  className='form-select' >
                                <option value='' disable>Select Type</option>
                                <option value='SELLER'>Seller</option>
                                <option value='BUYER'>Buyer</option>
                             
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                       
                    </div>
                   
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
