import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import { useSearchParams } from "react-router-dom";
import UploadDoc from "../../../../Common/UploadDoc";
import axios from "axios";
import CustLoader from "../../../../Common/CustLoader";
import Notification from "../../../../Common/notification";

export default function AddNewBrokerLead() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const [dataList, setDataList] = useState();
  const [rmList,setrmList] = useState([])
  const [searchParams] = useSearchParams();
  const [BrokerLeadDetails, setBrokerLeadDetails] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadDocuments, setUploadDocuments] = useState({
    firstInvoiceLink: null,
    invoiceLink: null,
    firstPaymentReceiptUrl:null,
    finalPaymentReceiptUrl:null
  });

  const currentPerPage = 10;

  const handleDocUpload = async (e, docType,setFieldValue) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadStatus((prevStatus) => ({
      ...prevStatus,
      [docType]: "Pending",
    }));

    const formData = new FormData();
    formData.append("file", file);
    // const URL = `https://dev-journey-api.houseeazy.com/images`;
    const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
    const reqHeaders = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(URL, formData, reqHeaders);
      const uploadDocRes = response.data;

      // Update the appropriate state based on imageType
      if (docType === "invoiceLink") {
        setUploadDocuments((prev) => ({
          ...prev,
          invoiceLink: uploadDocRes,
        }));
      }

      if (docType === "firstInvoiceLink") {
        setUploadDocuments((prev) => ({
          ...prev,
          firstInvoiceLink: uploadDocRes,
        }));
      }

      if(docType === "firstPaymentReceipt"){
        setUploadDocuments((prev) => ({
          ...prev,
          firstPaymentReceiptUrl: uploadDocRes,
        }));
      }

      if(docType === "finalPaymentReceipt"){
        setUploadDocuments((prev) => ({
          ...prev,
          finalPaymentReceiptUrl: uploadDocRes,
        }));
      }
      // Set upload status to done
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        [docType]: "Done",
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  useEffect(() => {
    const getResponse = setTimeout(function() {
      (async () => {
        const getRMList = setTimeout(function() {
          (async () => {
            const response = await JourneyAjax({
              // url: `/relation-managers?filter={"offset": ${skip},"limit": ${currentPerPage},"order":["createdAt DESC"]}`,
              url: `/relation-managers?filter={"order":["createdAt DESC"]}`,
              "x-api-key": "houseeazy-dev",
              "x-user": userValidToken || null,
              token: true,
            });
            // if (response && response.status === 200) {
            //   setDataList(response.data.data.relationManagers);
            //   setTotalCount(response.data.data.totalCount);
            //   setTotalRows(response.data.data.totalCount / 10);
            //   setCurrentPage(searchParams.get("page"));
            // }
            setrmList(response.data.data.relationManagers)
          })();
        }, 100);
        const response = await JourneyAjax({
          url: `/brokers/admin/broker-leads?filter={"where":{"id": ${JSON.stringify(
            urlParams.BrokerLeadId
          )}}}`,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          token: true,
        });

        if (response.status === 200) {
          const brokerLead = response.data.data.brokerLeads[0];
          setBrokerLeadDetails(response.data.data.brokerLeads[0]);
      
          const invoiceLink = brokerLead.finalInvoice ? brokerLead.finalInvoice.link : null;
          const firstInvoiceLink = brokerLead.firstTransactionInvoice ? brokerLead.firstTransactionInvoice.link : null;
          const finalPaymentReceiptUrl = brokerLead.finalInvoice ? brokerLead.finalInvoice.paymentReceipt : null;
          const firstPaymentReceiptUrl = brokerLead.firstTransactionInvoice ? brokerLead.firstTransactionInvoice.paymentReceipt : null;
      
          setUploadDocuments({
              invoiceLink: [invoiceLink],
              firstInvoiceLink: [firstInvoiceLink],
              finalPaymentReceiptUrl: [finalPaymentReceiptUrl],
              firstPaymentReceiptUrl: [firstPaymentReceiptUrl]
          });
      }
      })();
    }, 100);
    const getbroker = setTimeout(function() {
      (async () => {
        const response = await JourneyAjax({
          url: `/brokers?filter={"where":{"id":${JSON.stringify(urlParams.BrokerId)}}}`,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          token: true,
        });

        if (response.status === 200) {
          setDataList(response.data.data.brokers);
        }
       
      })();
    }, 100);

    return () => {
      clearInterval(getbroker);
      clearInterval(getResponse);
    };
  }, []);

  function isPDFUrl(url) {
    if (typeof url === "string") {
      return url && url.toLowerCase().endsWith(".pdf");
    }
  }

  const addBroker = async (values, event) => {
    const data= {
      name: values.name,
      comment: values.comment,
      visitDone:(values.visitDone==="true")||(values.visitDone===true)?true:false,
      status: values.status,
      finalInvoice: {}
    };

    if(values.visitSchedule){
      data.visitSchedule=values.visitSchedule
    }

    const {
      firstPaymentReceiptUrl,
      finalPaymentReceiptUrl
    } = uploadDocuments;
  
    const isSeller = values.leadType === "seller";
  
    // Handle invoiceLink
  
    // Handle AMA signed and leadType as seller
    if (values.isAmaSigned && isSeller) {
      data.isAmaSigned = values.isAmaSigned === "true" || values.isAmaSigned === true;
  
      if (data.isAmaSigned) {
        data.firstTransactionInvoice = {
          amount: values.firstAmount,
          status: values.firstInvoiceStatus && values.firstInvoiceStatus.length > 0
            ? values.firstInvoiceStatus
            : "inprocess",
          date: BrokerLeadDetails && BrokerLeadDetails.firstTransactionInvoice&&BrokerLeadDetails.firstTransactionInvoice.date
        };
        data.firstTransactionInvoice.invoiceRequest = BrokerLeadDetails && BrokerLeadDetails.firstTransactionInvoice&&BrokerLeadDetails.firstTransactionInvoice.invoiceRequest
        if (firstPaymentReceiptUrl && firstPaymentReceiptUrl[0]) {
          data.firstTransactionInvoice.paymentReceipt = firstPaymentReceiptUrl[0];
        }
        data.firstTransactionInvoice.allowInvoiceRaise = values.allowFirstInvoiceRaise?values.allowFirstInvoiceRaise:false;
        if(values.firstComment){
          data.firstTransactionInvoice.comment = values.firstComment;
        }
        if (uploadDocuments.firstInvoiceLink ) {
          data.firstTransactionInvoice.link = uploadDocuments.firstInvoiceLink[0];
        }
      }
    }
  
    // Handle finalInvoice
    if (values.invoiceAmount) {
      data.finalInvoice.amount = values.invoiceAmount;
      data.finalInvoice.date =  BrokerLeadDetails && BrokerLeadDetails.finalInvoice&&BrokerLeadDetails.finalInvoice.date
     data.finalInvoice.invoiceRequest = BrokerLeadDetails && BrokerLeadDetails.finalInvoice&&BrokerLeadDetails.finalInvoice.invoiceRequest
  
      if (finalPaymentReceiptUrl && finalPaymentReceiptUrl[0]) {
        data.finalInvoice.paymentReceipt = finalPaymentReceiptUrl[0];
      }
      data.finalInvoice.allowInvoiceRaise = values.allowFinalInvoiceRaise?values.allowFinalInvoiceRaise:false;
      data.finalInvoice.status = values.finalInvoiceStatus && values.finalInvoiceStatus.length > 0
          ? values.finalInvoiceStatus
          : "inprocess";

      if(values.finalComment){
        data.finalInvoice.comment = values.finalComment;
      }
      if (uploadDocuments.invoiceLink) {
        data.finalInvoice.link = uploadDocuments.invoiceLink[0];
      }
    
    }
  
    // Validate conditions before making the API call
    if (isSeller) {
      if (values.status === "inprocess" && !values.firstAmount ) {
        toast.error("Please provide First Transaction Invoice Amount and Invoice Date before changing status to Inprocess");
        return;
      }
  
      if (values.status === "dealClosed" && (!values.invoiceAmount  || !values.firstAmount )) {
        toast.error("Please provide First and Final Invoice Details Date before changing status to Deal Closed");
        return;
      }
    } else {
      if (values.status === "dealClosed" && (!values.invoiceAmount )) {
        toast.error("Please provide Final Invoice Amount and Invoice Date before changing status to Deal Closed");
        return;
      }
    }

    // Make API call to update broker lead
    try {
      const update = await JourneyAjax({
        url: `/brokers/admin/${urlParams.BrokerId}/broker-leads/${urlParams.BrokerLeadId}`,
        "x-api-key": "houseeazy-dev",
        "x-user": userValidToken || null,
        method: "PATCH",
        data: data,
        token: true,
        loader: true
      });
  
      if (update.data.error) {
        toast.error("Something Went Wrong Please Check");
        return;
      }
  
      if (update.status === 200) {
        toast.success("Broker Lead Updated Successfully");
        setTimeout(() => {
          navigate("/admin/property-journey/broker-lead");
        }, 500);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  
  return (
    <ContentLayout title="Edit Lead" notification={<Notification screen={`/leads/${urlParams.BrokerLeadId}`} url={`/brokerLead/pushNotification?filter={"where":{"id":"${urlParams.BrokerLeadId}"}}`} />}>
      <Helmet>
        <title>Edit Lead </title>
      </Helmet>
      
      <DataView title="Infomation">
        {BrokerLeadDetails === null ? (
          <DataLoader />
        ) : (
          <Formik
          initialValues={{
            name: BrokerLeadDetails && BrokerLeadDetails.name,
            email: BrokerLeadDetails && BrokerLeadDetails.email,
            mobile: BrokerLeadDetails && BrokerLeadDetails.mobile,
            leadType: BrokerLeadDetails && BrokerLeadDetails.leadType,
            comment: BrokerLeadDetails && BrokerLeadDetails.comment,
            project: BrokerLeadDetails && BrokerLeadDetails.project,
            invoiceLink: BrokerLeadDetails && BrokerLeadDetails.invoiceLink,
            isAmaSigned:
              (BrokerLeadDetails &&
                BrokerLeadDetails.isAmaSigned === "true") ||
              (BrokerLeadDetails && BrokerLeadDetails.isAmaSigned === true)
                ? true
                : false,
            visitDone:
            (BrokerLeadDetails &&
              BrokerLeadDetails.visitDone === "true") ||
            (BrokerLeadDetails && BrokerLeadDetails.visitDone === true)
              ? true
              : false,
            visitSchedule:BrokerLeadDetails && BrokerLeadDetails.visitSchedule&&new Date( BrokerLeadDetails.visitSchedule ).toISOString().split('T')[0],
            // First Transaction Invoice
            firstAmount:
              BrokerLeadDetails &&
              BrokerLeadDetails.firstTransactionInvoice &&
              BrokerLeadDetails.firstTransactionInvoice.amount,
            firstInvoiceStatus:
              BrokerLeadDetails &&
              BrokerLeadDetails.firstTransactionInvoice &&
              BrokerLeadDetails.firstTransactionInvoice.status,
            firstPaymentReceipt:BrokerLeadDetails &&
            BrokerLeadDetails.firstTransactionInvoice &&
            BrokerLeadDetails.firstTransactionInvoice.paymentReceipt,
            allowFirstInvoiceRaise: BrokerLeadDetails &&
              BrokerLeadDetails.firstTransactionInvoice &&
              BrokerLeadDetails.firstTransactionInvoice.allowInvoiceRaise,
            firstComment: BrokerLeadDetails &&
            BrokerLeadDetails.firstTransactionInvoice &&
            BrokerLeadDetails.firstTransactionInvoice.comment,
            // Final Invoice
            finalInvoiceStatus:
              BrokerLeadDetails &&
              BrokerLeadDetails.finalInvoice &&
              BrokerLeadDetails.finalInvoice.status,
            invoiceAmount:
              BrokerLeadDetails &&
              BrokerLeadDetails.finalInvoice &&
              BrokerLeadDetails.finalInvoice.amount,
            finalComment: BrokerLeadDetails &&
              BrokerLeadDetails.finalInvoice &&
              BrokerLeadDetails.finalInvoice.comment,
            finalPaymentReceipt:BrokerLeadDetails &&
              BrokerLeadDetails.finalInvoice &&
              BrokerLeadDetails.finalInvoice.paymentReceipt,
            allowFinalInvoiceRaise: BrokerLeadDetails &&
            BrokerLeadDetails.finalInvoice &&
            BrokerLeadDetails.finalInvoice.allowInvoiceRaise,
            status: BrokerLeadDetails && BrokerLeadDetails.status,
            relationManagerId:BrokerLeadDetails&&BrokerLeadDetails.relationManagerId,
            brokerId: BrokerLeadDetails && BrokerLeadDetails.brokerId,
          }}

          validationSchema={Yup.object({
            name: Yup.string().required("Lead Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .nullable(),
            mobile: Yup.string().required("Mobile Number is required"),
            leadType: Yup.string().required("Lead Type is required"),
            project: Yup.string().required("Project is required"),
            brokerId: Yup.string().required("Broker is required"),
            comment: Yup.string().nullable(),
            invoiceAmount: Yup.number()
              .typeError("Invoice Amount must be a number")
              .nullable(),
            invoiceDate: Yup.date().nullable(),
            // paymentStatus: Yup.string().nullable(),
            status: Yup.string().required("Status is required"),
          })}
          onSubmit={(values, event) => {
            addBroker(values, event);
          }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup label="Lead Name" required>
                      <Field
                        placeholder="Lead Name"
                        name="name"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="name" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup label="Email">
                      <Field
                        placeholder="Email"
                        type="email"
                        name="email"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="email" />
                      </ErrorField>
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup label="Mobile" required>
                      <Field
                        placeholder="Mobile Number"
                        type="string"
                        name="mobile"
                        disabled={true}
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="mobile" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="leadType" required>
                      <Field
                        as="select"
                        name="leadType"
                        disabled={true}
                        className="form-control"
                      >
                        <option value="buyer">Buyer</option>
                        <option value="seller">Seller</option>
                      </Field>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Project" required>
                      <Field
                        placeholder="Project"
                        name="project"
                        className="form-control"
                        disabled={true}
                      />
                      <ErrorField>
                        <ErrorMessage name="project" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Broker" required>
                      <Field
                        as="select"
                        name="brokerId"
                        disabled={true}
                        className="form-control"
                      >
                        {dataList &&
                          dataList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Field>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Relation Manager" required>
                      <Field
                        as="select"
                        name="relationManagerId"
                        className="form-select"
                      >
                        {rmList &&
                          rmList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Field>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Comment">
                      <Field
                        placeholder="Comment"
                        name="comment"
                        className="form-control"
                      />
                      <ErrorField>
                        <ErrorMessage name="comment" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                      <FormGroup label="Visit Done">
                        <Field
                          as="select"
                          name="visitDone"
                          className="form-select"
                        >
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="visitDone" />
                        </ErrorField>
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                <FormGroup label="Visit Schedule">
                  <Field
                    type="date"
                    placeholder="Valid Till"
                    name="visitSchedule"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="visitSchedule" />
                  </ErrorField>
                </FormGroup>
              </div>
                  {values.leadType === "seller" ? (
                    <div className="col-md-6">
                      <FormGroup label="AMA Signed" required>
                        <Field
                          as="select"
                          name="isAmaSigned"
                          className="form-select"
                        >
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </Field>
                        <ErrorField>
                          <ErrorMessage name="isAmaSigned" />
                        </ErrorField>
                      </FormGroup>
                    </div>
                  ) : null}
                  
                  {(values.isAmaSigned === "true" ||
                    values.isAmaSigned === true) &&
                  values.leadType === "seller" ? (
                    <div className="col-md-12 mt-3">
                      <hr className="w-50 my-4"/>
                      <h3>First Transaction Invoice</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup label="Amount">
                            <Field
                              type="number"
                              placeholder="amount"
                              name="firstAmount"
                              className="form-control"
                            />
                            <ErrorField>
                              <ErrorMessage name="firstAmount" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          {" "}
                          <FormGroup label="Allow Invoice Raise">
                            <Field
                              type="checkbox"
                              name="allowFirstInvoiceRaise"
                            />
                            <ErrorField>
                              <ErrorMessage name="allowFirstInvoiceRaise" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-6">
                        <FormGroup label="Comment">
                          <Field
                            placeholder="Comment"
                            name="firstComment"
                            className="form-control"
                          />
                          <ErrorField>
                            <ErrorMessage name="firstComment" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup label="First Invoice Request">
                          <p>
                            {BrokerLeadDetails &&
                            BrokerLeadDetails.finalInvoice &&
                            BrokerLeadDetails.finalInvoice.invoiceRequest ===
                              true
                              ? "True"
                              : "False"}
                          </p>
                        </FormGroup>
                      </div>
                      </div>
                      {(BrokerLeadDetails &&
                        BrokerLeadDetails.firstTransactionInvoice &&
                        BrokerLeadDetails.firstTransactionInvoice
                          .invoiceRequest) && (
                          <FormGroup label="First Payment Receipt">
                            <div className="custom-file-upload-wrapper">
                              <span>Choose File</span>
                              <label
                                htmlFor="firstPaymentReceipt"
                                className="custom-file-upload"
                              >
                                {uploadDocuments.firstPaymentReceiptUrl
                                  ? uploadDocuments.firstPaymentReceiptUrl
                                  : "No File Chosen"}
                              </label>
                              <div className="custom-file-preview">
                                {uploadDocuments && (
                                  <a
                                    href={
                                      uploadDocuments.firstPaymentReceiptUrl
                                        ? uploadDocuments.firstPaymentReceiptUrl
                                        : ""
                                    }
                                    target="_blank"
                                  >
                                    {uploadStatus.firstPaymentReceipt ===
                                      "Pending" && <CustLoader />}
                                    {!isPDFUrl(
                                      uploadDocuments.firstPaymentReceiptUrl
                                        ? uploadDocuments.firstPaymentReceiptUrl
                                        : ""
                                    ) ? (
                                      <img
                                        src={
                                          uploadDocuments.firstPaymentReceiptUrl
                                            ? uploadDocuments.firstPaymentReceiptUrl
                                            : ""
                                        }
                                        width={200}
                                      />
                                    ) : (
                                      <img
                                        src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                        width={40}
                                      />
                                    )}
                                  </a>
                                )}
                              </div>
                            </div>

                            <input
                              id="firstPaymentReceipt"
                              type="file"
                              name="firstPaymentReceipt"
                              onChange={(e) =>
                                handleDocUpload(e, "firstPaymentReceipt")
                              }
                              style={{ display: "none" }}
                              accept=".jpg, .jpeg, .png, .webp, application/pdf"
                            />
                            <ErrorField>
                              <ErrorMessage name="firstPaymentReceipt" />
                            </ErrorField>
                          </FormGroup>
                        )}

                      <div className="row">
                        <div className="col-md-6">
                          <FormGroup label="First Transaction Status">
                            <Field
                              as="select"
                              name="firstInvoiceStatus"
                              className="form-select"
                            >
                              <option value="inprocess">In Process</option>
                              <option value="cleared">Cleared</option>
                            </Field>
                          </FormGroup>
                        </div>

                        {BrokerLeadDetails &&
                          BrokerLeadDetails.firstTransactionInvoice &&
                          BrokerLeadDetails.firstTransactionInvoice.date && (
                            <div className="col-md-6">
                              <FormGroup label="First Invoice Date">
                                <p>
                                  {new Date(
                                    BrokerLeadDetails &&
                                      BrokerLeadDetails.firstTransactionInvoice &&
                                      BrokerLeadDetails.firstTransactionInvoice
                                        .date
                                  ).toLocaleDateString()}
                                </p>
                                <ErrorField>
                                  <ErrorMessage name="invoiceDate" />
                                </ErrorField>
                              </FormGroup>
                            </div>
                          )}
                      </div>

                      <div className="col-md-12">
                        <div className="col-md-12">
                          <FormGroup label="First Invoice Link">
                            {/* <Field
                          placeholder="Invoice Link"
                          name="invoiceLink"
                          className="form-control"
                          disabled={true}
                        /> */}
                            {!BrokerLeadDetails &&
                              BrokerLeadDetails.firstTransactionInvoice &&
                              BrokerLeadDetails.firstTransactionInvoice
                                .link && (
                                <UploadDoc
                                  docUrl={
                                    BrokerLeadDetails &&
                                    BrokerLeadDetails.firstTransactionInvoice &&
                                    BrokerLeadDetails.firstTransactionInvoice
                                      .link
                                      ? BrokerLeadDetails &&
                                        BrokerLeadDetails.firstTransactionInvoice &&
                                        BrokerLeadDetails
                                          .firstTransactionInvoice.link
                                      : uploadDocuments.firstInvoiceLink
                                  }
                                  name="firstInvoiceLink"
                                  uploadStatus={uploadStatus.firstInvoiceLink}
                                  handleDocUpload={handleDocUpload}
                                />
                              )}
                            <ErrorField>
                              <ErrorMessage name="firstInvoiceLink" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          {!isPDFUrl(
                            BrokerLeadDetails &&
                              BrokerLeadDetails.firstTransactionInvoice &&
                              BrokerLeadDetails.firstTransactionInvoice.link
                              ? BrokerLeadDetails &&
                                  BrokerLeadDetails.firstTransactionInvoice &&
                                  BrokerLeadDetails.firstTransactionInvoice.link
                              : ""
                          ) ? (
                            <img
                              src={
                                BrokerLeadDetails &&
                                BrokerLeadDetails.firstTransactionInvoice &&
                                BrokerLeadDetails.firstTransactionInvoice.link
                                  ? BrokerLeadDetails &&
                                    BrokerLeadDetails.firstTransactionInvoice &&
                                    BrokerLeadDetails.firstTransactionInvoice
                                      .link
                                  : ""
                              }
                              width={200}
                            />
                          ) : (
                            <img
                              onClick={() => {
                                window.open(
                                  BrokerLeadDetails &&
                                    BrokerLeadDetails.firstTransactionInvoice &&
                                    BrokerLeadDetails.firstTransactionInvoice
                                      .link,
                                  "_blank"
                                );
                              }}
                              className="cursor-pointer"
                              src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                              width={40}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <hr className="w-50 my-4"/>
                  <div className="row mt-2">
                    <h3>Final Invoice</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup label="Invoice Amount">
                          <Field
                            type="number"
                            placeholder="Invoice Amount"
                            name="invoiceAmount"
                            className="form-control"
                          />
                          <ErrorField>
                            <ErrorMessage name="invoiceAmount" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <FormGroup label="Allow Invoice Raise">
                            <Field
                              type="checkbox"
                              name="allowFinalInvoiceRaise"
                            />
                            <ErrorField>
                              <ErrorMessage name="allowFinalInvoiceRaise" />
                            </ErrorField>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <FormGroup label="Comment">
                          <Field
                            placeholder="Comment"
                            name="finalComment"
                            className="form-control"
                          />
                          <ErrorField>
                            <ErrorMessage name="finalComment" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                      <FormGroup label="Final Invoice Request">
                        <p>
                          {BrokerLeadDetails &&
                          BrokerLeadDetails.finalInvoice &&
                          BrokerLeadDetails.finalInvoice.invoiceRequest === true
                            ? "True"
                            : "False"}
                        </p>
                      </FormGroup>
                    </div>
                    </div>
                    <div>
                      {(BrokerLeadDetails &&
                        BrokerLeadDetails.finalInvoice &&
                        BrokerLeadDetails.finalInvoice.invoiceRequest )&& (
                          <FormGroup label="Final Payment Receipt">
                            <div className="custom-file-upload-wrapper">
                              <span>Choose File</span>
                              <label
                                htmlFor="finalPaymentReceipt"
                                className="custom-file-upload"
                              >
                                {uploadDocuments.finalPaymentReceiptUrl
                                  ? uploadDocuments.finalPaymentReceiptUrl
                                  : "No File Chosen"}
                              </label>
                              <div className="custom-file-preview">
                                {uploadDocuments && (
                                  <a
                                    href={
                                      uploadDocuments.finalPaymentReceiptUrl
                                        ? uploadDocuments.finalPaymentReceiptUrl
                                        : ""
                                    }
                                    target="_blank"
                                  >
                                    {uploadStatus.finalPaymentReceipt ===
                                      "Pending" && <CustLoader />}
                                    {!isPDFUrl(
                                      uploadDocuments.finalPaymentReceiptUrl
                                        ? uploadDocuments.finalPaymentReceiptUrl
                                        : ""
                                    ) ? (
                                      <img
                                        src={
                                          uploadDocuments.finalPaymentReceiptUrl
                                            ? uploadDocuments.finalPaymentReceiptUrl
                                            : ""
                                        }
                                        width={200}
                                      />
                                    ) : (
                                      <img
                                        src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                        width={40}
                                      />
                                    )}
                                  </a>
                                )}
                              </div>
                            </div>

                            <input
                              id="finalPaymentReceipt"
                              type="file"
                              name="finalPaymentReceipt"
                              onChange={(e) =>
                                handleDocUpload(e, "finalPaymentReceipt")
                              }
                              style={{ display: "none" }}
                              accept=".jpg, .jpeg, .png, .webp, application/pdf"
                            />
                            <ErrorField>
                              <ErrorMessage name="finalPaymentReceipt" />
                            </ErrorField>
                          </FormGroup>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup label="Final invoice status">
                          <Field
                            as="select"
                            name="finalInvoiceStatus"
                            className="form-select"
                          >
                            <option value="inprocess">In Process</option>
                            <option value="cleared">Cleared</option>
                          </Field>
                        </FormGroup>
                      </div>
                      {BrokerLeadDetails &&
                        BrokerLeadDetails.finalInvoice &&
                        BrokerLeadDetails.finalInvoice.date && (
                          <div className="col-md-6">
                            <FormGroup label="Invoice Date">
                              <p>
                                {new Date(
                                  BrokerLeadDetails &&
                                    BrokerLeadDetails.finalInvoice &&
                                    BrokerLeadDetails.finalInvoice.date
                                ).toLocaleDateString()}
                              </p>
                              <ErrorField>
                                <ErrorMessage name="invoiceDate" />
                              </ErrorField>
                            </FormGroup>
                          </div>
                        )}
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <FormGroup label="Invoice Link">
                          {/* <Field
                          placeholder="Invoice Link"
                          name="invoiceLink"
                          className="form-control"
                          disabled={true}
                        /> */}
                          {!BrokerLeadDetails &&
                            BrokerLeadDetails.finalInvoice &&
                            BrokerLeadDetails.finalInvoice.link && (
                              <UploadDoc
                                docUrl={
                                  BrokerLeadDetails &&
                                  BrokerLeadDetails.finalInvoice &&
                                  BrokerLeadDetails.finalInvoice.link
                                    ? BrokerLeadDetails &&
                                      BrokerLeadDetails.finalInvoice &&
                                      BrokerLeadDetails.finalInvoice.link
                                    : uploadDocuments.invoiceLink
                                }
                                name="invoiceLink"
                                uploadStatus={uploadStatus.invoiceLink}
                                handleDocUpload={handleDocUpload}
                              />
                            )}
                          <ErrorField>
                            <ErrorMessage name="invoiceLink" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        {!isPDFUrl(
                          BrokerLeadDetails &&
                            BrokerLeadDetails.finalInvoice &&
                            BrokerLeadDetails.finalInvoice.link
                            ? BrokerLeadDetails &&
                                BrokerLeadDetails.finalInvoice &&
                                BrokerLeadDetails.finalInvoice.link
                            : ""
                        ) ? (
                          <img
                            src={
                              BrokerLeadDetails &&
                              BrokerLeadDetails.finalInvoice &&
                              BrokerLeadDetails.finalInvoice.link
                                ? BrokerLeadDetails &&
                                  BrokerLeadDetails.finalInvoice &&
                                  BrokerLeadDetails.finalInvoice.link
                                : ""
                            }
                            width={200}
                          />
                        ) : (
                          <img
                            onClick={() => {
                              window.open(
                                BrokerLeadDetails &&
                                  BrokerLeadDetails.finalInvoice &&
                                  BrokerLeadDetails.finalInvoice.link,
                                "_blank"
                              );
                            }}
                            className="cursor-pointer"
                            src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                            width={40}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <FormGroup label="Payment Status">
                      <Field
                        as="select"
                        name="paymentStatus"
                        className="form-select"
                      >
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                      </Field>
                    </FormGroup>
                  </div> */}
                   <hr className="w-50 my-4"/>
                   <div className="row">
                  <div className="col-md-6">
                    <FormGroup label="Status" required>
                      <Field as="select" name="status" className="form-select">
                        <option value="submitted">Submitted</option>
                        <option value="dealClosed">Deal Closed</option>
                      </Field>
                    </FormGroup>
                  </div>
                   </div>
                </div>

                <div className="col-md-2 d-grid">
                  <Button type="submit">Update</Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </DataView>
    </ContentLayout>
  );
}
