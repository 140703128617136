import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import TableContainer from '../../../UI/Dashboard/TableContainer'
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import BasicPropertyList from '../../../../helpers/BasicPropertyList'
import RandomId from '../../../../helpers/RandomId'
import GetCookie from '../../../../helpers/GetCookie'
import FileBase64 from 'react-file-base64';

export default function EditBuyer() {
    const urlParams = useParams()
    const [productDetail, setProductDetail] = useState(null)
    const [bigImage, setBigImage] = useState()
    const [documents ,setDocuments] = useState([])
    const [property, setProperty] = useState([])
    const [docFile, setDocFile] = useState()
    const [docBase64, setDocBase64] = useState()
    const [docBase64Final, setDocBase64Final] = useState()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(()=>{
            (async()=>{
                const productInfo = await Ajax({
                    url: `/user/getUserList?_id=${urlParams.BuyerId}`,
                    token: true
                })
                const productDocs = await Ajax({
                    url: `/buyer/document?user=${urlParams.BuyerId}&type=BUYER&limit=false`,
                    token: true
                })
                const propertyList = await BasicPropertyList()
                setProperty(propertyList)
                if(productInfo.data.status === 'SUCCESS!'){
                    setProductDetail(productInfo.data.result[0])
                }
                if(productDocs.data.status === 'SUCCESS!'){
                    setDocuments(productDocs.data.result)
                }
            })()
        },100)

        return()=>{
            clearInterval(getResponse)
        }
    },[])

    //Get Base 64
    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            setDocBase64Final(baseURL)
            resolve(baseURL);
          };
          //console.log(fileInfo);
        });
    };
    const getBase64Doc = e => {
    
        const file = e.target.files[0]
    
        getBase64(file)
          .then(result => {
            file["base64"] = result;
            //console.log("File Is", file);
            setDocBase64(result, file)
          })
          .catch(err => {
            //console.log(err);
          });
          setDocFile(e.target.files[0])
    };

    //Add Product
    const propDocument = useRef()
    const addDocument = async (values, reset) => {
        if(!productDetail.name || !productDetail.email){
            toast.error('Please update name and email')
            navigate(`/admin/user/${urlParams.BuyerId}`)
            return false
        }
        if(propDocument.current.value === ''){
            toast.error('Please upload document')
            return false
        }
        const data = {
            productId: values.property,
            name: values.document_name,
            user: urlParams.BuyerId,
        }
        const formData = new FormData()
        if(propDocument.current.value !== ''){
            for(let i = 0; i < propDocument.current.files.length; i++){
                formData.append("file", propDocument.current.files[i])
            }
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/buyer/document`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            // var index = event.nativeEvent.target.selectedIndex;
            // event.nativeEvent.target[index].text
            const docData = {
                "email": productDetail.email ? productDetail.email : 'info@houseeazy.com',
                "name": productDetail.name ? productDetail.name : 'HouseEazy',
                "recipientId": `${RandomId(8, 'number')}`,
                "subject": `${values.document_name}`,
                "documentId": `${RandomId(8, 'number')}`,
                "documentName": values.document_name,
                "token": GetCookie('houseeazy'),
                "documentBase64": docBase64Final.split(',')[1],
                "userDocumentId": update.data.result._id,
            }
            const documentSend = await Ajax({
                url: '/product/sendAgreement',
                method: 'POST',
                data: docData,
                token: true,
                loader:true
            })
            if(documentSend.data.error){
                toast.error(documentSend.data.error)
                return false
            }
            if(documentSend.data.status === 'SUCCESS!'){
                reset()
                propDocument.current.value = ''
                toast.success('Document Added Successfully')
                setTimeout(function(){
                    window.location.reload()
                },500)
            }
        }
    }

    return (
        <ContentLayout title="Upload Document">
            <Helmet>
                <title>Upload Document</title>
            </Helmet>
            <DataView title="Infomation">
                {productDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        "document_name": '',
                        "property": ''
                    }}
                    validationSchema={Yup.object({
                        document_name: Yup.string()
                        .required('Field is Required'),
                        property: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, {resetForm}) => {
                        addDocument(values, resetForm)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-info" data-bs-toggle="tab" className="nav-link active">Buyer Info</a></li>
                        <li className="nav-item"><a href="#tab-meta-info" data-bs-toggle="tab" className="nav-link">Upload Document</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab-info">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <FormGroup label="Buyer Name">
                                        <div className="form-control">
                                            {productDetail.name ? productDetail.name : 'NA'}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Buyer Email">
                                        <div className="form-control">
                                            {productDetail.email ? productDetail.email : 'NA'}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Buyer Phone">
                                        <div className="form-control">
                                            {productDetail.phoneNumber ? productDetail.phoneNumber : 'NA'}
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-meta-info">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <FormGroup label="Property" required>
                                        <Field as="select" name="property" className='form-select'>
                                            <option value="" disabled>Select Property</option>
                                            {property.length === 0 ? <option>Data not found</option> : property.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name} ({data.bhk ? data.bhk.name : ''}-{data.unitNumber})</option>
                                            ))}
                                        </Field>
                                        <ErrorField><ErrorMessage name="property"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Document Name" required>
                                        <Field placeholder="Document Name" name="document_name" className='form-control' />
                                        <ErrorField><ErrorMessage name="document_name"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup label="Upload Document" required>
                                        <input type="file" accept=".docx,.doc,.pdf" name="document" className='form-control' role='button' onChange={getBase64Doc} ref={propDocument} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='col-md-2 d-grid'>
                                <Button type="submit">Upload Document</Button>
                            </div>
                            <div className='mt-3'>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th width="100">S.No</th>
                                            <th>Title</th>
                                            <th>View</th>
                                            <th width="100" className='text-center'>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documents.length > 0 ? documents.map((e, index)=>(
                                            <tr key={e._id}>
                                                <td width="100">{index + 1}</td>
                                                <td>{e.name}</td>
                                                <td>
                                                    <a href={e.file.path} target="_blank" rel="noreferrer" className='btn btn-primary'>
                                                        View
                                                    </a>
                                                </td>
                                                <td width="100" className='text-center'>
                                                    <DeleteButton url="/buyer/document" id={e._id} />
                                                </td>
                                            </tr>
                                        )) : 
                                            <tr>
                                                <td colSpan={4}>Document not uploaded yet</td>
                                            </tr>
                                        }
                                    </tbody>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-2 d-grid'>
                        <Button type="submit">Update Product</Button>
                    </div> */}
                </Form>
                </Formik> }
            </DataView>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{productDetail !== null && productDetail.societyName ? productDetail.societyName.name : 'NA'}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <img src={bigImage} className='img-fluid' />
                    </div>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}
