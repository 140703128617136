import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../../UI/Form/FormGroup'
import ErrorField from '../../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../../UI/Dashboard/DataLoader'
import Button from '../../../../../UI/Form/Button'
import ContentLayout from '../../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"

export default function LoanOfferEdit() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/finance/homeloan?_id=${urlParams.LoanOfferId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateData = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.heading,
            roi: values.roi,
            processingFee: values.processingFee,
            tenure: values.tenure,
            tenureAmount: values.tenureAmount,
            monthlyEmi: 'dsad'
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/finance/${urlParams.LoanOfferId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Loan Offer">
            <Helmet>
                <title>Edit Edit Loan Offer</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
                initialValues={{ 
                    heading: infoDetail.name ? infoDetail.name : '',
                    roi: infoDetail.roi ? infoDetail.roi : '',
                    processingFee: infoDetail.processingFee ? infoDetail.processingFee : '',
                    tenure: infoDetail.tenure ? infoDetail.tenure : '',
                    tenureAmount: infoDetail.tenureAmount ? infoDetail.tenureAmount : '',
                }}
                validationSchema={Yup.object({
                    heading: Yup.string().required('Field is Required'),
                    roi: Yup.string().required('Field is Required'),
                    processingFee: Yup.string().required('Field is Required'),
                    tenure: Yup.string().required('Field is Required'),
                    tenureAmount: Yup.string().required('Field is Required'),
                })}
                onSubmit={(values) => {
                    updateData(values)
                }}
            >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="heading" className='form-control' />
                                <ErrorField><ErrorMessage name="heading"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Rate of Interest" required>
                                <Field placeholder='Rate of Interest' name="roi" className='form-control' />
                                <ErrorField><ErrorMessage name="roi"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Processing Fee" required>
                                <Field placeholder='Processing Fee' name="processingFee" className='form-control' />
                                <ErrorField><ErrorMessage name="processingFee"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Tenure" required>
                                <Field placeholder='Tenure' name="tenureAmount" className='form-control' />
                                <ErrorField><ErrorMessage name="tenureAmount"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Tenure Description" required>
                                <Field placeholder='Tenure Description' name="tenure" className='form-control' />
                                <ErrorField><ErrorMessage name="tenure"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Image" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                            {infoDetail.file &&
                            <img src={infoDetail.file.thumbnail} alt="prcess icon" height="100" width="100" className='img-fluid mb-4' /> }
                        </div>
                    </div>
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
            </Formik> }
            </DataView>
        </ContentLayout>
    )
}
