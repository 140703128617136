import React, {useEffect, useState} from 'react'
import { toast } from 'react-toastify';
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../../UI/Dashboard/Pagination';
import FilterLayout from '../../../../UI/Dashboard/FilterLayout';
import FormGroup from '../../../../UI/Form/FormGroup';
import Button from '../../../../UI/Form/Button';

export default function ProjectAreaList() {
    const [search, setSearch] = useState(false)
    const [filterKey, setFilterKey] = useState({})
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()

    useEffect(()=>{
        const getResponse = setTimeout(function(){  
            (async()=>{ 
            const response = await Ajax({
                url: `/project/ProjectArea${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
                token: true,
                ...(pageParams && {loader: true})
            })
            if(response.data.status === "SUCCESS!"){
                setDataList(response.data.result)
                setTotalResult(response.data.count)
                setTotalRows(response.data.count / 10)
                setInitialPage(pageParams)
                setCurrentPage(searchParams.get('page'))
            }
            })()
        },100) 
        return()=>{
            clearInterval(getResponse)
        }
    },[location])

    const setFilter = (e) => {
        const fieldName = e.target.getAttribute("name")
        let updatedValue = {};
        if(e.target.value.trim().length === 0) {
            delete filterKey[fieldName]
            return false
        }
        updatedValue[fieldName] = e.target.value.trim()
        setFilterKey(oldValue => ({...oldValue, ...updatedValue}));
    }


    const filterHandler = async() => {
        const filterResponse = await Ajax({
            url: '/project/ProjectArea?' + new URLSearchParams(filterKey),
            token: true,
            loader: true
        })
        console.log("h", filterResponse)
        if(filterResponse.data.status === "SUCCESS!"){
            console.log("REDDD", filterResponse)
            setDataList(filterResponse.data.result)
            toast.error('Matching Record not found!',{
                autoClose: 1500
            })
            setSearch(true)
        }else{
            setCurrentPage(0)
            setInitialPage(0)
            setDataList(filterResponse.data.result)
            setTotalRows(filterResponse.data.count / 10)
            setTotalResult(filterResponse.data.count)
            pageParams && navigate('/admin/home/service')
            return false
        }
    }

    console.log("service", dataList)

    return (
    <ContentLayout title='Project Area' addNew='/admin/property-management/projectsarea/add'>
        <div className="row">
        <Helmet>
            <title>Project Areas</title>
        </Helmet>
        {dataList == null ? '' : (dataList.length > 0 || search === true) &&
        <FilterLayout>
            <div className='row'>
                <div className='col-md-3'>
                    <FormGroup label="Property Area">
                        <input type="text" name="search" placeholder="Property Area" onChange={setFilter} className="form-control" />
                    </FormGroup>
                </div>
            </div>
            <div className="col-md-2 d-grid">
                <Button type="button" icon="fas fa-filter" onclick={filterHandler}>Filter</Button>
            </div>
        </FilterLayout>
        }
        <DataView title="Project Area List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-center">Average (in Lakhs)</td>
                        <td className="text-center">Area (sq.ft)</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Edit</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={9}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={9} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start text-capitalize">{data.projectId?data.projectId.name:''}</td>
                        <td className="text-center ">{data.average?data.average:''}</td>
                        <td className="text-center ">{data.area?data.area:''}</td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/property-management/projectsarea/${data._id}`}</EditButton>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/property-management/projectsarea' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
